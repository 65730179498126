import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FutureEventsService } from '../future-events.service';
import { ReservationService } from '../reservation.service';
import { TableReservationGridComponent } from '../table-reservation-grid/table-reservation-grid.component';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-customer-reservations',
  templateUrl: './customer-reservations.component.html',
  styleUrls: ['./customer-reservations.component.css']
})
export class CustomerReservationsComponent implements OnInit {
  dateId: number;
  eventInfo: any;
  eventDate: any;
  reservationForm: FormGroup;
  gridStyle: any;
  formStyle: any;
  inputStyle: any;
  buttonStyle: any;
  selectedSeats: any[];
  seatCount: number;
  seatReserved: boolean;
  reservationFailed: boolean;

  @ViewChild(TableReservationGridComponent) tableDesignGridComponent: TableReservationGridComponent;

  constructor(private route: ActivatedRoute, private eventsService: FutureEventsService, private formBuilder: FormBuilder, private reservationService: ReservationService, private router: Router) {
    this.seatReserved = false;
    this.reservationFailed = false;
   }

  saveReservation() {
    if (this.reservationForm.valid && this.selectedSeats && this.selectedSeats.length > 0) {
      const reservationFormData = this.reservationForm.value;
      let reservation = {
        customerName: reservationFormData.customerName,
        customerEmail: reservationFormData.customerEmail,
        seats: this.selectedSeats,
        paid: false,
        date: {
          id: this.eventDate.id,
          date: this.eventDate.date,
          soldOut: this.eventDate.soldOut
        }
      };
      // console.log(reservation);
      this.reservationService.submitReservation(reservation).pipe(
        catchError((error) => {
          // Handle errors here
          console.error('Error during reservation:', error);
          return of('Reservation failed');
        })
      ).subscribe((response) => {
        if (response === 'Reservation failed') {
          this.reservationFailed = true;
          this.getEventData();
          setTimeout(() => {
            this.tableDesignGridComponent.initializeGrid();
          }, 2000);
        } else {
          this.seatReserved = true;
        }
      });
    } else {
      console.log("form invalid");
      this.reservationFailed = true;
    }
  }

  goHome() {
    this.router.navigate(['/']);
  }

  setGridStyle() {
    var aspectRatio = window.innerWidth / window.innerHeight;
    this.gridStyle.position = "fixed";
    this.gridStyle.height = window.innerHeight * 101/512 + "px";
    this.gridStyle.width = window.innerWidth * 131/512 + "px";
    this.gridStyle.left = window.innerWidth * 20/512 + "px";
    this.gridStyle.top = window.innerHeight/2 - window.innerWidth * 101/512 + "px";
    if (aspectRatio < 0.84) {
      this.gridStyle.left = window.innerWidth * 45/512 + "px";
      this.gridStyle.top = window.innerWidth * 35/512 + "px";
    }
  }

  setFormStyle() {
    var aspectRatio = window.innerWidth / window.innerHeight;
    this.formStyle.position = "fixed";
    this.formStyle.textAlign = "left";
    this.formStyle.fontSize = window.innerWidth * 4/512 + "px";
    this.formStyle.lineHeight = window.innerWidth * 6.8/512 + "px";
    this.formStyle.height = window.innerHeight * 3/4 + "px";
    this.formStyle.width = window.innerWidth * 175/512 + "px";
    this.formStyle.left = window.innerWidth * 305/512 + "px";
    this.formStyle.top = window.innerHeight/2 - window.innerWidth * 101/512 + "px";
    if (aspectRatio < 0.84) {
      this.formStyle.left = window.innerWidth * 45/512 + "px";
      this.formStyle.width = 8/5 * window.innerWidth * 262/512 + "px";
      this.formStyle.fontSize = 7/5 * window.innerHeight * 4/512 + "px";
      this.formStyle.lineHeight = 7/5 * window.innerHeight * 6.3/512 + "px";
      this.formStyle.top = 7/5 * window.innerWidth * 202/512 + window.innerWidth * 45/512 + "px";
    }
  }

  setInputStyle() {
    let aspectRatio = window.innerWidth/window.innerHeight;
    this.inputStyle.padding = window.innerWidth * 2/512 + "px";
    this.inputStyle.width = window.innerWidth * 100/512 + "px";
    this.inputStyle.borderWidth = window.innerWidth * 1/512 + "px";
    this.inputStyle.fontSize = window.innerWidth * 3/512 + "px";
    if (aspectRatio < 0.84) {
      this.inputStyle.padding = window.innerHeight * 2/512 + "px";
      this.inputStyle.width = window.innerHeight * 100/512 + "px";
      this.inputStyle.borderWidth = window.innerHeight * 1/512 + "px";
      this.inputStyle.fontSize = window.innerHeight * 5/512 + "px";
    }
  }

  setButtonStyle() {
    let aspectRatio = window.innerWidth/window.innerHeight;
    this.buttonStyle.fontSize = window.innerWidth * 3/512 + "px";
    // this.buttonStyle.height = window.innerWidth * 7/512 + "px";
    this.buttonStyle.padding = window.innerWidth * 1.5/512 + "px";
    this.buttonStyle.borderWidth = window.innerWidth * 1/512 + "px";
    if (aspectRatio < 0.84) {
      this.buttonStyle.fontSize = window.innerHeight * 3/306 + "px";
      // this.buttonStyle.height = window.innerWidth * 7/306 + "px";
      this.buttonStyle.padding = window.innerHeight * 1.5/306 + "px";
      this.buttonStyle.borderWidth = window.innerHeight * 1/306 + "px";
    }
  }

  setStyles() {
    this.setGridStyle();
    this.setFormStyle();
    this.setInputStyle();
    this.setButtonStyle();
  }

  getSeatCount() {
    this.selectedSeats = this.tableDesignGridComponent.getSelectedSeats();
    this.seatCount = this.selectedSeats.length;
  }

  getEventData() {
    this.eventsService.getFutureDateById(this.dateId).subscribe((data: any) => {
      this.eventDate = data;
      this.eventInfo = {
        name: data.event.name,
        price: data.event.price,
        date: this.eventsService.formatDateWithoutTimeZone(data.date),
        squares: data.squares
      };
    });
  }

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    this.getSeatCount();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setStyles();
  }

  ngOnInit(): void {
    this.reservationForm = this.formBuilder.group({
      customerName: [null, Validators.required],
      customerEmail: [null, Validators.required],
      seats: this.formBuilder.array([])
    });

    this.gridStyle = {};
    this.formStyle = {};
    this.inputStyle = {};
    this.buttonStyle = {};

    this.route.params.subscribe(params => {
      this.dateId = params['dateId'];
    });

    this.getEventData();
    this.setStyles();
    this.getSeatCount();
  }

}
