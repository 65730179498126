import { Component, OnInit, HostListener, ChangeDetectorRef, NgZone, ViewChild, ElementRef } from '@angular/core';
import { PageStateService, PageState } from '../page-state.service'

@Component({
  selector: 'app-menu-cards',
  templateUrl: './menu-cards.component.html',
  styleUrls: ['./menu-cards.component.css'],
})
export class MenuCardsComponent implements OnInit {

  public PageState = PageState;

  menus: string[] = ["assets/menus/Menu_3.PNG", "assets/menus/Menu_4.PNG", "assets/menus/Menu_5.PNG", "assets/menus/Menu_1.PNG", "assets/menus/Menu_2.PNG", "assets/menus/Menu_6.PNG", "assets/menus/Menu_7.PNG", "assets/menus/Menu_8.PNG","assets/menus/Menu_9.PNG","assets/menus/Menu_10.PNG","assets/menus/Menu_11.PNG","assets/menus/Menu_12.PNG","assets/menus/Bologna.PNG","assets/menus/SevenFishes.PNG","assets/menus/Roma.PNG","assets/menus/Firenze.PNG"];
  menuImages: any[] = [];

  leftStaticStyle: any;
  leftTransitStyle: any;
  rightStaticStyle: any;
  rightTransitStyle: any;
  pageClosedStyleLeft: any;
  pageClosedStyleRight: any;

  @ViewChild('leftTransit') leftTransit: ElementRef;
  @ViewChild('rightTransit') rightTransit: ElementRef;

  currentLeftCardIndex: number;
  currentRightCardIndex: number;

  isLeftCardMoving: boolean;
  isRightCardMoving: boolean;
  isRightCardMovingRight: boolean;
  topCounter: number;
  
  pageOpened: boolean = false;

  aspectRatio: number;
  previousTimestamp: number;

  constructor(private cdr: ChangeDetectorRef, private ngZone: NgZone, private state: PageStateService) { }

  setCardSizes() {
    this.aspectRatio = window.innerWidth / window.innerHeight;
    var narrow = this.aspectRatio < 512/320;
    let leftCutoff = this.state.getLeftCutoff();
    this.leftStaticStyle.backgroundImage = `url(${this.menuImages[this.currentLeftCardIndex].src})`;
    this.leftTransitStyle.backgroundImage = `url(${this.menuImages[this.currentLeftCardIndex].src})`;
    this.rightStaticStyle.backgroundImage = `url(${this.menuImages[this.currentRightCardIndex].src})`;
    this.rightTransitStyle.backgroundImage = `url(${this.menuImages[this.currentRightCardIndex].src})`;
    this.pageClosedStyleLeft.backgroundImage = `url(${this.menuImages[this.currentLeftCardIndex].src})`;
    this.pageClosedStyleRight.backgroundImage = `url(${this.menuImages[this.currentRightCardIndex].src})`;
    this.leftStaticStyle.left = window.innerWidth * 69/512 + "px";
    this.leftTransitStyle.left = window.innerWidth * 74/512 + "px";
    this.rightStaticStyle.left = window.innerWidth * 281/512 + "px";
    this.rightTransitStyle.left = window.innerWidth * 286/512 + "px";
    
    // if (aspectRatio < 0.65) {
    //     this.leftStaticStyle.top = window.innerWidth * 150/306 - this.state.getTopCutoff() + "px";
    //     this.leftTransitStyle.top = window.innerWidth * 145/306 - this.state.getTopCutoff() + "px";
    //     this.rightStaticStyle.top = window.innerWidth * 150/306 - this.state.getTopCutoff() + "px";
    //     this.rightTransitStyle.top = window.innerWidth * 145/306 - this.state.getTopCutoff() + "px";
    //     this.leftStaticStyle.height = window.innerWidth * 240/512 + "px";
    //     this.leftStaticStyle.width = window.innerWidth * 162/512 + "px";
    //     this.leftTransitStyle.height = window.innerWidth * 240/512 + "px";
    //     this.leftTransitStyle.width = window.innerWidth * 162/512 + "px";
    //     this.rightStaticStyle.height = window.innerWidth * 240/512 + "px";
    //     this.rightStaticStyle.width = window.innerWidth * 162/512 + "px";
    //     this.rightTransitStyle.height = window.innerWidth * 240/512 + "px";
    //     this.rightTransitStyle.width = window.innerWidth * 162/512 + "px";
    //     this.pageClosedStyleLeft.width = window.innerWidth * 15/306 + "px";
    //     this.pageClosedStyleLeft.height = window.innerWidth * 20/306 + "px";
    //     this.pageClosedStyleLeft.left = window.innerWidth * 62/306 + "px";
    //     this.pageClosedStyleLeft.top = window.innerWidth * 218/306 - this.state.getTopCutoff() + "px";
    // }
    if (this.aspectRatio < 0.95) {
        this.leftStaticStyle.top = window.innerWidth * 122/306 - this.state.getTopCutoff() + "px";
        this.leftTransitStyle.top = window.innerWidth * 118/306 - this.state.getTopCutoff() + "px";
        this.rightStaticStyle.top = window.innerWidth * 114/306 - this.state.getTopCutoff() + "px";
        this.rightTransitStyle.top = window.innerWidth * 110/306 - this.state.getTopCutoff() + "px";
        this.leftStaticStyle.height = window.innerWidth * 480/512 + "px";
        this.leftStaticStyle.width = window.innerWidth * 324/512 + "px";
        this.leftTransitStyle.height = window.innerWidth * 480/512 + "px";
        this.leftTransitStyle.width = window.innerWidth * 324/512 + "px";
        this.rightStaticStyle.height = window.innerWidth * 480/512 + "px";
        this.rightStaticStyle.width = window.innerWidth * 324/512 + "px";
        this.rightTransitStyle.height = window.innerWidth * 480/512 + "px";
        this.rightTransitStyle.width = window.innerWidth * 324/512 + "px";
        this.pageClosedStyleLeft.width = window.innerWidth * 15/306 + "px";
        this.pageClosedStyleLeft.height = window.innerWidth * 20/306 + "px";
        this.pageClosedStyleLeft.left = window.innerWidth * 62/306 + "px";
        this.pageClosedStyleLeft.top = window.innerWidth * 218/306 - this.state.getTopCutoff() + "px";
        this.rightStaticStyle.left = window.innerWidth * 79/512 + "px";
        this.rightTransitStyle.left = window.innerWidth * 84/512 + "px";
    } else if (narrow) {
        this.leftStaticStyle.top = window.innerHeight * 30 / 320 + "px";
        this.leftTransitStyle.top = window.innerHeight * 25/320 + "px";
        this.rightStaticStyle.top = window.innerHeight * 30/320 + "px";
        this.rightTransitStyle.top = window.innerHeight * 25/320 + "px";
        this.leftStaticStyle.height = window.innerWidth * 240/512 + "px";
        this.leftStaticStyle.width = window.innerWidth * 162/512 + "px";
        this.leftTransitStyle.height = window.innerWidth * 240/512 + "px";
        this.leftTransitStyle.width = window.innerWidth * 162/512 + "px";
        this.rightStaticStyle.height = window.innerWidth * 240/512 + "px";
        this.rightStaticStyle.width = window.innerWidth * 162/512 + "px";
        this.rightTransitStyle.height = window.innerWidth * 240/512 + "px";
        this.rightTransitStyle.width = window.innerWidth * 162/512 + "px";
        this.pageClosedStyleLeft.width = window.innerHeight * 15/320 + "px";
        this.pageClosedStyleLeft.height = window.innerHeight * 20/320 + "px";
        this.pageClosedStyleLeft.left = window.innerHeight * 178/320 - leftCutoff + "px";
        this.pageClosedStyleLeft.top = window.innerHeight * 114/320 + "px";
    } else {
        this.leftStaticStyle.top = window.innerWidth * 30/512 + "px";
        this.leftTransitStyle.top = window.innerWidth * 25/512 + "px";
        this.rightStaticStyle.top = window.innerWidth * 30/512 + "px";
        this.rightTransitStyle.top = window.innerWidth * 25/512 + "px";
        this.leftStaticStyle.height = window.innerHeight * 240 / 320 + "px";
        this.leftStaticStyle.width = window.innerHeight * 162 / 320 + "px";
        this.leftTransitStyle.height = window.innerHeight * 240 / 320 + "px";
        this.leftTransitStyle.width = window.innerHeight * 162 / 320 + "px";
        this.rightStaticStyle.height = window.innerHeight * 240 / 320 + "px";
        this.rightStaticStyle.width = window.innerHeight * 162 / 320 + "px";
        this.rightTransitStyle.height = window.innerHeight * 240 / 320 + "px";
        this.rightTransitStyle.width = window.innerHeight * 162 / 320 + "px";
        this.pageClosedStyleLeft.width = window.innerWidth * 15/512 + "px";
        this.pageClosedStyleLeft.height = window.innerWidth * 20/512 + "px";
        this.pageClosedStyleLeft.left = window.innerWidth * 178/512 + "px";
        this.pageClosedStyleLeft.top = window.innerWidth * 114/512 + "px";
    }
    this.pageClosedStyleRight.width = this.pageClosedStyleLeft.width;
    this.pageClosedStyleRight.height = this.pageClosedStyleLeft.height;
    this.pageClosedStyleRight.left = this.pageClosedStyleLeft.left;
    this.pageClosedStyleRight.top = this.pageClosedStyleLeft.top;
    this.cdr.detectChanges();
  }

  moveLeftCard() {
    if (parseInt(this.leftTransitStyle.left, 10) < parseInt(this.rightStaticStyle.left, 10)) {
        this.leftTransitStyle.left = parseInt(this.leftTransitStyle.left, 10) + window.innerWidth * 0.01 + "px";
    } else {
        this.isLeftCardMoving = false;
        this.rightTransitStyle.backgroundImage = `url(${this.menuImages[this.currentRightCardIndex].src})`;
        this.leftTransitStyle.visibility = "hidden";
        this.setCardSizes();
        this.leftTransitStyle.visibility = "visible";
    }

    if (this.isLeftCardMoving) {
      setTimeout(() => {
        this.ngZone.runOutsideAngular(() => requestAnimationFrame(() => this.moveLeftCard()));
      }, 5);
    }
    this.cdr.detectChanges();
  }

  moveRightCard(currentTimestamp: number) {
    const elapsed = currentTimestamp - this.previousTimestamp;
    const sf = elapsed / 4;
    this.previousTimestamp = currentTimestamp;
    if (this.aspectRatio < 0.95) {
      if (this.isRightCardMovingRight && parseInt(this.rightTransitStyle.left, 10) < parseInt(this.rightStaticStyle.left, 10) + parseInt(this.rightStaticStyle.width,10) + window.innerWidth * 10/306) {
        this.rightTransitStyle.left = parseInt(this.rightTransitStyle.left, 10) + window.innerWidth * 0.01 * sf + "px";
      } else if (parseInt(this.rightTransitStyle.left, 10) > parseInt(this.rightStaticStyle.left, 10)) {
        this.isRightCardMovingRight = false;
        this.rightTransitStyle.zIndex = 800;
        this.rightTransitStyle.left = parseInt(this.rightTransitStyle.left, 10) - window.innerWidth * 0.01 * sf + "px";
      } else {
        this.isRightCardMoving = false;
        this.leftTransitStyle.backgroundImage = `url(${this.menuImages[this.currentRightCardIndex].src})`;
        this.rightTransitStyle.visibility = "hidden";
        this.setCardSizes();
        this.rightTransitStyle.zIndex = 1000;
        this.rightTransitStyle.visibility = "visible";
      }
      this.topCounter++;
      if (parseInt(this.leftStaticStyle.top,10) - parseInt(this.rightTransitStyle.top,10) > 0 && this.topCounter % 5 === 0) {
        this.topCounter = 0;
        this.rightTransitStyle.top = parseInt(this.rightTransitStyle.top, 10) + window.innerWidth * 0.003 * sf + "px";
      }
    } else {
      if (parseInt(this.rightTransitStyle.left, 10) > parseInt(this.leftStaticStyle.left, 10)) {
        this.rightTransitStyle.left = parseInt(this.rightTransitStyle.left, 10) - window.innerWidth * 0.01 * sf + "px";
      } else {
          this.isRightCardMoving = false;
          this.leftTransitStyle.backgroundImage = `url(${this.menuImages[this.currentRightCardIndex].src})`;
          this.rightTransitStyle.visibility = "hidden";
          this.setCardSizes();
          this.rightTransitStyle.visibility = "visible";
      }
    }

    if (this.isRightCardMoving) {
      setTimeout(() => {
        this.ngZone.runOutsideAngular(() => requestAnimationFrame(() => this.moveRightCard(performance.now())));
      }, 5);
    }
    this.cdr.detectChanges();
  }

  leftTransitClick() {
    if (this.currentLeftCardIndex - 1 >= 0) {
        this.currentLeftCardIndex--;
    } else {
        this.currentLeftCardIndex = this.menuImages.length - 1;
    }
    if (this.currentRightCardIndex - 1 >= 0) {
        this.currentRightCardIndex--;
    } else {
        this.currentRightCardIndex = this.menuImages.length - 1;
    }
    this.leftStaticStyle.backgroundImage = `url(${this.menuImages[this.currentLeftCardIndex].src})`;
    this.rightTransitStyle.zIndex = 900;
    this.leftTransitStyle.zIndex = 1000;
    this.isLeftCardMoving = true;
    this.moveLeftCard();
  }

  rightTransitClick() {
    if (this.currentRightCardIndex + 1 < this.menuImages.length) {
        this.currentRightCardIndex++;
    } else {
        this.currentRightCardIndex = 0;
    }
    if (this.currentLeftCardIndex + 1 < this.menuImages.length) {
        this.currentLeftCardIndex++;
    } else {
        this.currentLeftCardIndex = 0;
    }
    this.rightStaticStyle.backgroundImage = `url(${this.menuImages[this.currentRightCardIndex].src})`;
    this.leftTransitStyle.zIndex = 900;
    this.rightTransitStyle.zIndex = 1000;
    this.isRightCardMoving = true;
    this.isRightCardMovingRight = true;
    this.topCounter = 0;
    this.previousTimestamp = performance.now();
    this.moveRightCard(this.previousTimestamp);
  }

  checkCollision(event): boolean {
    if (this.leftTransit.nativeElement.contains(event.target) || this.rightTransit.nativeElement.contains(event.target)){
      return true;
    } else {
      return false;
    }
  }

  openPage() {
    if (parseInt(this.pageClosedStyleLeft.height, 10)/parseInt(this.leftTransitStyle.height) < 0.99) {
      this.pageClosedStyleLeft.width = parseInt(this.pageClosedStyleLeft.width, 10) + 1/3*(parseInt(this.leftTransitStyle.width) - parseInt(this.pageClosedStyleLeft.width, 10)) + "px";
      this.pageClosedStyleLeft.height = parseInt(this.pageClosedStyleLeft.height, 10) + 1/3*(parseInt(this.leftTransitStyle.height) - parseInt(this.pageClosedStyleLeft.height, 10)) + "px";
      this.pageClosedStyleLeft.left = parseInt(this.pageClosedStyleLeft.left, 10) + 1/3*(parseInt(this.leftTransitStyle.left) - parseInt(this.pageClosedStyleLeft.left, 10)) + "px";
      this.pageClosedStyleLeft.top = parseInt(this.pageClosedStyleLeft.top, 10) + 1/3*(parseInt(this.leftTransitStyle.top) - parseInt(this.pageClosedStyleLeft.top, 10)) + "px";
      this.pageClosedStyleRight.width = this.pageClosedStyleLeft.width;
      this.pageClosedStyleRight.height = this.pageClosedStyleLeft.height;
      this.pageClosedStyleRight.top = parseInt(this.pageClosedStyleRight.top, 10) + 1/3*(parseInt(this.rightTransitStyle.top) - parseInt(this.pageClosedStyleRight.top, 10)) + "px";
      this.pageClosedStyleRight.left = parseInt(this.pageClosedStyleRight.left, 10) + 1/3*(parseInt(this.rightTransitStyle.left) - parseInt(this.pageClosedStyleRight.left, 10)) + "px";
      
      setTimeout(() => {
        this.ngZone.runOutsideAngular(() => requestAnimationFrame(() => this.openPage()));
      }, 50);
    } else {
      this.pageOpened = true;
    }
    this.cdr.detectChanges();
  }

  closePage() {
    if (parseInt(this.leftTransitStyle.height, 10)/parseInt(this.pageClosedStyleLeft.height) > 1.01) {
      this.leftTransitStyle.width = parseInt(this.leftTransitStyle.width, 10) + 1/3*(parseInt(this.pageClosedStyleLeft.width) - parseInt(this.leftTransitStyle.width, 10)) + "px";
      this.leftTransitStyle.height = parseInt(this.leftTransitStyle.height, 10) + 1/3*(parseInt(this.pageClosedStyleLeft.height) - parseInt(this.leftTransitStyle.height, 10)) + "px";
      this.leftTransitStyle.left = parseInt(this.leftTransitStyle.left, 10) + 1/3*(parseInt(this.pageClosedStyleLeft.left) - parseInt(this.leftTransitStyle.left, 10)) + "px";
      this.leftTransitStyle.top = parseInt(this.leftTransitStyle.top, 10) + 1/3*(parseInt(this.pageClosedStyleLeft.top) - parseInt(this.leftTransitStyle.top, 10)) + "px";
      this.leftStaticStyle.width = this.leftTransitStyle.width;
      this.leftStaticStyle.height = this.leftTransitStyle.height;
      this.leftStaticStyle.left = this.leftTransitStyle.left
      this.leftStaticStyle.top = this.leftTransitStyle.top;
      this.rightTransitStyle.width = this.leftTransitStyle.width;
      this.rightTransitStyle.height = this.leftTransitStyle.height;
      this.rightTransitStyle.left = parseInt(this.rightTransitStyle.left, 10) + 1/3*(parseInt(this.pageClosedStyleLeft.left) - parseInt(this.rightTransitStyle.left, 10)) + "px";
      this.rightTransitStyle.top = this.leftTransitStyle.top;
      this.rightStaticStyle.width = this.leftTransitStyle.width;
      this.rightStaticStyle.height = this.leftTransitStyle.height;
      this.rightStaticStyle.left = this.rightTransitStyle.left
      this.rightStaticStyle.top = this.leftTransitStyle.top;
      
      setTimeout(() => {
        this.ngZone.runOutsideAngular(() => requestAnimationFrame(() => this.closePage()));
      }, 50);
    } else {
      this.pageOpened = false;
      this.state.setCurrentState(PageState.HOME);
    }
    this.cdr.detectChanges(); 
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (this.state.currentState === PageState.PAST && !this.checkCollision(event) && this.pageOpened) {
      this.setCardSizes();
      this.closePage();
      // for (let i = 0; i < this.cardChildren.length; i++) {
      //     this.cardChildren[i].style.transition = "all 1s ease";
      // }
      this.cdr.detectChanges();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setCardSizes();
  }

  ngOnInit(): void {
  
    this.leftStaticStyle = {};
    this.leftTransitStyle = {};
    this.rightStaticStyle = {};
    this.rightTransitStyle = {};
    this.pageClosedStyleLeft = {};
    this.pageClosedStyleRight = {};

    this.currentLeftCardIndex = 0;
    this.currentRightCardIndex = 1;

    this.isLeftCardMoving = false;
    this.isRightCardMoving = false;

    for (let i = 0; i < this.menus.length; i++) {
      this.menuImages[i] = new Image();
      this.menuImages[i].src = this.menus[i];
    }

    this.setCardSizes();

    this.openPage();
  }

}
