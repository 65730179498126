
<div id="futureDates" #futureDates style="z-index:900; position:absolute; background-size:cover;" [ngStyle]="pageOpened ? calendarOpenedStyle : calendarStyle">
    <div *ngIf="textEnabled">
        <h1>{{ selectedMonthName }} {{ selectedYear }}</h1>
        <br>
        <div *ngIf="this.selectedMonth <= 4"><h2>Residency at Forin Cafe. Click <a style="font-size: inherit; padding: 0px"href="https://forin-cafe.square.site/" target="_blank">here</a> for reservations.</h2></div>
        <div *ngIf="this.selectedMonth > 4">No future events at this time. Check back soon!</div>
        <!-- <div *ngFor="let event of this.currentMonthEvents">
            <h2>{{ event.name }}</h2>
            <div *ngFor="let date of event.dates">
                {{ date.date }}<br><br>
                <button [disabled]="date.soldOut" (click)="openReservation(date.dateId)" [ngStyle]="buttonStyle">{{ date.soldOut ? "Sold Out" : "Book Now" }}</button><br><br>
            </div>
        </div> -->
        <!-- <div *ngIf="this.currentMonthEvents.length == 0"><br>No future events at this time. Check back soon!</div> -->
    </div>
</div>
<button *ngIf="textEnabled" id="previousMonthButton" #previousMonthButton [disabled]="currentMonth === selectedMonth && currentYear === selectedYear" (click)="previousMonth()" style="z-index:990; position:absolute;" [ngStyle]="previousButtonStyle">Prev</button>
<button *ngIf="textEnabled" id="nextMonthButton" #nextMonthButton (click)="nextMonth()" style="z-index:990; position:absolute;" [ngStyle]="nextButtonStyle">Next</button>