<div *ngFor="let event of this.events">
    <h2>{{ event.name }}</h2>
    <div *ngFor="let date of event.dates">
        {{ date.date }}
        <button (click)="openReservation && date.dateId === dateId ? openReservation = false : openReservations(date.dateId)">{{ openReservation && date.dateId === dateId ? "Close" : "Open" }} Reservations</button><br><br>
        <div class="grid-container" *ngIf="openReservation && date.dateId === dateId">
            <div style="justify-content:right">
                <app-admin-reservation-grid [squares]="squares"></app-admin-reservation-grid>
            </div>
            <div>
                <div *ngFor="let reservation of reservations" [ngStyle]="reservationsStyle">
                    <h3>{{ reservation.customerName }}</h3>
                    Email: {{ reservation.customerEmail }}
                </div>
            </div>
        </div>
    </div>
</div>