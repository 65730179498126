import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { FutureEventsService } from '../future-events.service';
import { TableDesignGridComponent } from '../table-design-grid/table-design-grid.component';



@Component({
  selector: 'app-new-event-input',
  templateUrl: './new-event-input.component.html',
  styleUrls: ['./new-event-input.component.css']
})
export class NewEventInputComponent implements OnInit {
  dateForm: FormGroup;
  @Output() notifyParent: EventEmitter<any> = new EventEmitter<any>();
  showForm: Boolean = false;
  formStyle: any;
  inputStyle: any;
  dateInputStyle: any;
  buttonStyle: any;
  usePreviousSeatingChart: boolean = false;
  newDate: boolean = true;
  dateList: any[];
  selectedDate: any;
  @ViewChild(TableDesignGridComponent) tableDesignGridComponent: TableDesignGridComponent;

  constructor(private formBuilder: FormBuilder, private futureEvents: FutureEventsService) { 
    this.formStyle = {};
    this.inputStyle = {};
    this.buttonStyle = {};
    this.dateInputStyle = {};
    this.dateList = [];
    this.selectedDate = {};
  }

  ngOnInit() {
    this.dateForm = this.formBuilder.group({
      eventName: [null, Validators.required],
      eventPrice: [null, Validators.required],
      selectedDate: [null]
    });
    this.getStyles();
  }

  addDate() {
    const inputDate = this.dateForm.get('selectedDate').value;
    let isInDateList = false;
    if (inputDate) {
      for (let i = 0; i < this.dateList.length; i++) {
        if (inputDate === this.dateList[i].date) isInDateList = true;
      }
      if (!isInDateList) {
        this.tableDesignGridComponent.setDate(inputDate);
        this.dateList.push({
          date: inputDate,
          grid: this.tableDesignGridComponent.getGrid(),
          jsonDateObject: this.tableDesignGridComponent.getGridJsonObject()
        });
        this.selectedDate = {};
        this.tableDesignGridComponent.resetGrid();
      }
    }
  }

  selectDate(date: any) {
    if (this.selectedDate) {
      this.selectedDate.grid = this.tableDesignGridComponent.getGrid();
      this.selectedDate.jsonDateObject = this.tableDesignGridComponent.getGridJsonObject();
      for (let i = 0; i < this.dateList.length; i++) {
        if (this.selectedDate.date === this.dateList[i].date) {
          this.dateList[i] = this.selectedDate;
        }
      }
    }
    for (let i = 0; i < this.dateList.length; i++) {
      if (this.dateList[i].date === date) {
        this.selectedDate = this.dateList[i];
        this.tableDesignGridComponent.displayInputGrid(this.selectedDate.grid);
      }
    }
  }

  removeDate(date: any) {
    this.dateList = this.dateList.filter(d => d.date !== date);
    if (this.selectedDate.date === date) {
      if (this.dateList.length > 0) {
        this.selectedDate = this.dateList[0];
      } else {
        this.selectedDate = {};
      }
    }
  }

  // Function to add additional dates to the form
  addAdditionalDate() {
    const additionalDates = this.dateForm.get('additionalDates') as FormArray;
    additionalDates.push(this.formBuilder.control(null, Validators.required));
  }

  removeAdditionalDate(index: number) {
    const additionalDates = this.dateForm.get('additionalDates') as FormArray;
    additionalDates.removeAt(index);
  }
  
  getAdditionalDatesControls() {
    return (this.dateForm.get('additionalDates') as FormArray).controls;
  }

  castToFormControl(control: AbstractControl): FormControl {
    return control as FormControl;
  }

  showFormOnClick() {
    this.showForm = true;
    this.notifyParent.emit();
  }

  hideFormOnClick() {
    this.showForm = false;
    this.dateForm.reset();
    this.dateList = [];
    this.notifyParent.emit();
  }

  getStyles() {
    this.getFormStyle();
    this.getInputStyle();
    this.getDateInputStyle();
    this.getButtonStyle();
  }

  getFormStyle() {
    this.formStyle.position = "absolute";
    this.formStyle.left = window.innerWidth * 315/512 + "px";
    this.formStyle.width = window.innerWidth * 175/512 + "px";
    this.formStyle.height = window.innerWidth * 198/512 + "px";
    this.formStyle.borderWidth = window.innerWidth * 1/512 + "px";
    this.formStyle.paddingTop = window.innerWidth * 15/512 + "px";
    this.formStyle.fontSize = window.innerWidth * 5/512 + "px";
  }

  getInputStyle() {
    this.inputStyle.padding = window.innerWidth * 2/512 + "px";
    this.inputStyle.width = window.innerWidth * 75/512 + "px";
    this.inputStyle.borderWidth = window.innerWidth * 1/512 + "px";
    this.inputStyle.fontSize = window.innerWidth * 4/512 + "px";
  }

  getDateInputStyle() {
    this.dateInputStyle.padding = window.innerWidth * 2/512 + "px";
    this.dateInputStyle.width = window.innerWidth * 75/512 + "px";
    this.dateInputStyle.borderWidth = window.innerWidth * 1/512 + "px";
    this.dateInputStyle.fontSize = window.innerWidth * 3/512 + "px";
  }

  getButtonStyle() {
    this.buttonStyle.borderWidth = window.innerWidth * 1/512 + "px";
    this.buttonStyle.padding = window.innerWidth * 3/512 + "px";
    this.buttonStyle.fontSize = window.innerWidth * 4/512 + "px";
  }

  addFutureEvent() {
    if (this.dateForm.valid) {
      // Perform actions with the form data
      const formData = this.dateForm.value;

      const newEvent = {
        name: formData.eventName,
        price: formData.eventPrice,
        dates: []
      };

      this.dateList.forEach(d => {
        newEvent.dates.push(d.jsonDateObject);
      }); 

      this.futureEvents.saveFutureEvent(newEvent).subscribe(() => {});
      this.showForm = false;
      this.dateForm.get('selectedDate').reset();
      this.dateList = [];
      this.notifyParent.emit();
    } else {
      console.log("form invalid");
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.getStyles();
  }
  
}
