import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GridService {
  numRows = 10;
  numCols = 9;

  constructor() { }
}
