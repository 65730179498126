
<div class="reservation-container" *ngIf="!seatReserved">
    
    <div [ngStyle]="gridStyle">
        <app-table-reservation-grid [squares]="eventInfo.squares"></app-table-reservation-grid>
    </div>
    <br>
    <div [ngStyle]="formStyle">
        <form [formGroup]="reservationForm">
            <h2>Reservation for {{ eventInfo.name }}</h2>
            <h3>{{ eventInfo.date }}</h3>
            House Rules:<br><br>
            1. No reservations will be held until Venmo payment is received with a couch emoji to @elizabeth-grothe<br><br>
            2. Any cancellations must be made 48 hours in advance for a refund. If you and your party are more than 30 minutes late, your reservation may be terminated.<br><br>
            3. Remember that this is not a restaurant, it is a dinner party at my home. Come with an open mind and be ready for a good time!
            <br><br>
            <label for="customerName">Name:</label>
            <input type="text" id="customerName" formControlName="customerName" [ngStyle]="inputStyle" autocomplete="off" required>
            <br><br>
            <label for="customerEmail">Email:</label>
            <input type="text" id="customerEmail" formControlName="customerEmail" [ngStyle]="inputStyle" autocomplete="off" required>
            <br><br>
            Seats Selected: {{ seatCount ? seatCount : 0 }}
            <br><br>
            Total: ${{ seatCount ? seatCount * eventInfo.price : 0 }} (${{ eventInfo.price }} per seat)
            <br><br>
            <button type="submit" [ngStyle]="buttonStyle" (click)="saveReservation()">Reserve Seat{{ seatCount > 1 ? "s" : "" }}</button>...
            <button type="button" [ngStyle]="buttonStyle" (click)="goHome()">Cancel</button>
        </form>
    </div>
</div>
<div *ngIf="seatReserved">
    Congratulations! Your reservation has been confirmed pending payment.<br><br>
    <button (click)="goHome()">Return Home</button>
</div>
<app-reservation-failed *ngIf="reservationFailed" [(isVisible)]="reservationFailed"></app-reservation-failed>
