import { Component, OnInit, ElementRef, ViewChild, HostListener, NgZone, ChangeDetectorRef } from '@angular/core';
import { PageStateService } from '../page-state.service';
import { ScoreService } from '../score.service';

@Component({
  selector: 'app-frog-game',
  templateUrl: "./frog-game.component.html",
  styleUrls: ['./frog-game.component.css']
})
export class FrogGameComponent implements OnInit {

  constructor(private ngZone: NgZone, private cdr: ChangeDetectorRef, private scoreService: ScoreService, private state: PageStateService) {}

  @ViewChild('gameCanvas', { static: true }) 
  canvas: ElementRef<HTMLCanvasElement>;
  ctx: CanvasRenderingContext2D;

  scalingFactor: number;
  canvasWidth: number;
  canvasHeight: number;
  canvasTop: number;
  canvasLeft: number;
  aspectRatio: number;
  bodyFont: any;

  kitchenBackground: any;
  frogSpriteSheet: any;
  fishSprite: any;
  toySprite: any;
  mittensSpriteSheet: any;
  gameOverText: string;
  restartText: string;

  gameStarted: boolean;
  gameOver: boolean;
  isJumping: boolean;
  narrow: boolean;
  leftCutoff: number;

  frogSpriteWidth: number;
  frogSpriteHeight: number;
  frogSpriteRow: number;
  frogSpriteColumn: number;
  frogSpriteColumnCount: number;
  frogSpriteRowCount: number;
  frogSpriteInterval: number
  frogSpriteTimer: number;
  frogX: number;
  frogY: number;
  frogWidth: number;
  frogHeight: number;

  mittensWidth: number;
  mittensHeight: number;
  mittensSpriteWidth: number;
  mittensSpriteHeight: number;
  mittensSpriteColumn: number;
  mittensSpriteCount: number;
  mittensSpriteInterval: number;
  mittensSpriteTimer: number;

  initialFrogSpeed: number;
  frogSpeed: number;
  gravity: number;
  obstacleWidth: number;
  obstacleHeight: number;
  obstacleSpeed: number;
  obstacleSpacing: number;
  lastObstacleX: number;

  obstacles: any[] = new Array();

  drawing: boolean;
  isMobile: boolean;

  jumpButtonStyle: any;

  previousTimestamp: number;
  scoreTimer: number;

  setCanvasSize() {
    this.canvas.nativeElement.width = window.innerWidth * 375/512;
    this.canvasWidth = window.innerWidth * 375/512;
    this.canvas.nativeElement.height = window.innerWidth * 190/512;
    this.canvasHeight = window.innerWidth * 190/512;
    this.canvasTop = window.innerWidth * 20/512;
    this.canvasLeft = window.innerWidth * 117/512;
    this.canvas.nativeElement.style.zIndex = "30";
    this.aspectRatio = window.innerWidth / window.innerHeight;
    this.narrow = this.state.isNarrow();

    this.jumpButtonStyle.left = window.innerWidth * 370/512 + "px";
    this.jumpButtonStyle.top = window.innerWidth * 165/512 + "px";
    this.jumpButtonStyle.height = window.innerWidth * 36/512 + "px";
    this.jumpButtonStyle.width = window.innerWidth * 36/512 + "px";

    if (this.narrow) {
      this.leftCutoff = this.state.getLeftCutoff();
      this.canvasHeight = window.innerHeight * 190/320;
      this.canvas.nativeElement.width = window.innerHeight * 375/320;
      this.canvas.nativeElement.height = window.innerHeight * 190/320;
      this.canvasWidth = window.innerHeight * 375/320;
      this.canvasTop = window.innerHeight * 20/320;
      this.canvasLeft = window.innerHeight * 117/320 - this.leftCutoff;

      this.jumpButtonStyle.left = window.innerHeight * 370/320 - this.state.getLeftCutoff() + "px";
      this.jumpButtonStyle.top = window.innerHeight * 165/320 + "px";
      this.jumpButtonStyle.height = window.innerHeight * 36/320 + "px";
      this.jumpButtonStyle.width = window.innerHeight * 36/320 + "px";
    }

    if (this.aspectRatio < 0.95) { 
      this.canvasWidth = window.innerWidth;
      this.canvasHeight = window.innerWidth * 185/306*(1 + 0.2*(306*window.innerHeight/window.innerWidth - 322)/340);
      this.canvasTop = window.innerWidth * 126/306 - this.state.getTopCutoff();
      this.canvas.nativeElement.width = window.innerWidth;
      this.canvas.nativeElement.height = this.canvasHeight;
      this.canvasLeft = 0;
      // this.scalingFactor = this.canvasHeight / (350*512/320);

      this.jumpButtonStyle.left = window.innerWidth * 250/306 + "px";
      this.jumpButtonStyle.top = window.innerWidth * 292/306*(1 + 0.2*(306*window.innerHeight/window.innerWidth - 322)/340) - this.state.getCounterTopCutoff() + "px";
      this.jumpButtonStyle.height = window.innerWidth * 36/306*(1 + 0.25*(306*window.innerHeight/window.innerWidth - 322)/340) + "px";
      this.jumpButtonStyle.width = window.innerWidth * 36/306*(1 + 0.25*(306*window.innerHeight/window.innerWidth - 322)/340) + "px";
    }

    if (this.aspectRatio < 0.65) {
      this.canvasWidth = window.innerWidth;
      this.canvasHeight = window.innerWidth * 185/306*(1 + 0.2*(306*window.innerHeight/window.innerWidth - 322)/340);
      this.canvasTop = window.innerWidth * 126/306 - this.state.getTopCutoff();
      this.canvas.nativeElement.width = window.innerWidth;
      this.canvas.nativeElement.height = this.canvasHeight;
      this.canvasLeft = 0;
      // this.scalingFactor = this.canvasHeight / (350*512/320);

      this.jumpButtonStyle.left = window.innerWidth * 200/306 + "px";
      this.jumpButtonStyle.top = window.innerWidth * 360/306*(1 + 0.2*(306*window.innerHeight/window.innerWidth - 322)/340) - this.state.getCounterTopCutoff() + "px";
      this.jumpButtonStyle.height = window.innerWidth * 72/306*(1 + 0.25*(306*window.innerHeight/window.innerWidth - 322)/340) + "px";
      this.jumpButtonStyle.width = window.innerWidth * 72/306*(1 + 0.25*(306*window.innerHeight/window.innerWidth - 322)/340) + "px";
    }
    this.scalingFactor = this.canvasHeight / 413;
  }

  setInitialFrogPosition() {
    this.frogX = 10;
    this.frogY = this.canvasHeight - 1.2*this.frogHeight;
  }

  drawFrog(elapsed: number) {
    this.frogSpriteInterval = Math.floor(70/elapsed);
    this.ctx.drawImage(
        this.frogSpriteSheet,
        this.frogSpriteWidth * this.frogSpriteColumn,
        this.frogSpriteHeight * this.frogSpriteRow,
        this.frogSpriteWidth,
        this.frogSpriteHeight,
        this.frogX,
        this.frogY,
        this.frogWidth,
        this.frogHeight
    );
    
    if (this.isJumping) {
        if (this.frogSpriteTimer % this.frogSpriteInterval === 0) {
            this.frogSpriteColumn++;
        if (this.frogSpriteColumn === this.frogSpriteColumnCount) {
            this.frogSpriteColumn = 0;
            this.frogSpriteRow++;
            if (this.frogSpriteRow === this.frogSpriteRowCount) {
                this.frogSpriteRow = 0;
            }
        }
        }
        this.frogSpriteTimer ++;
    } else {
        this.frogSpriteRow = 0;
        this.frogSpriteColumn = 0;
        this.frogSpriteTimer = 0;
    }
  }

  drawObstacle(elapsed: number) {
    this.mittensSpriteInterval = Math.floor(90/elapsed);
    for (var i = 0; i < this.obstacles.length; i++) {
        if (this.obstacles[i].type < 2) {
            this.ctx.globalAlpha = 1;
            this.ctx.drawImage(
                this.mittensSpriteSheet,
                this.mittensSpriteWidth * this.mittensSpriteColumn,
                0,
                this.mittensSpriteWidth,
                this.mittensSpriteHeight,
                this.obstacles[i].x,
                this.obstacles[i].y,
                this.mittensWidth,
                this.mittensHeight
            )
        } else if (this.obstacles[i].type > 4) {
            this.ctx.drawImage(
                this.toySprite,
                0,0,560,560,
                this.obstacles[i].x,
                this.obstacles[i].y,
                this.obstacleWidth,
                this.obstacleHeight
            )
        } else {
            this.ctx.drawImage(
                this.fishSprite,
                0,0,560,560,
                this.obstacles[i].x,
                this.obstacles[i].y,
                this.obstacleWidth,
                this.obstacleHeight
            )
        }
    }
    if (this.mittensSpriteTimer % this.mittensSpriteInterval === 0) {
        this.mittensSpriteColumn++;
        if (this.mittensSpriteColumn === this.mittensSpriteCount) {
            this.mittensSpriteColumn = 0;
        }
    }
    this.mittensSpriteTimer++;
  }

  moveObstacle(elapsed: number) {
    var sf = elapsed/6;
    for (var i = 0; i < this.obstacles.length; i++) {
        this.obstacles[i].x -= this.obstacleSpeed*sf + this.scoreService.score / 2000;
    }
  }

  createObstacle() {
    var obstacleX: number = this.canvasWidth;
    var obstacleY: any;
    if (this.canvasWidth - this.lastObstacleX > this.obstacleSpacing) {
        var obstacleType = 5*Math.random();
        if (obstacleType < 2) {
            obstacleY = this.canvasHeight - 0.2*this.frogHeight - this.mittensHeight;
        } else {
            obstacleY = this.canvasHeight - 0.2*this.frogHeight - this.obstacleHeight;
        }
        this.obstacles.push({ x: obstacleX, y: obstacleY, type: obstacleType }); 
        this.obstacleSpacing = 3.5*this.frogWidth + 5*this.frogWidth*Math.random();
    }    
    if (this.obstacles.length > 0) {
        this.lastObstacleX = this.obstacles[this.obstacles.length - 1].x;
    }
  }

  checkCollision() {
    for (var i = 0; i < this.obstacles.length; i++) {
      // this.ctx.fillStyle = 'red';
      // this.ctx.fillRect(this.obstacles[i].x + 0.15*this.mittensWidth,this.obstacles[i].y + 0.2*this.obstacleHeight,5,5);
      // this.ctx.fillStyle = 'blue';
      // this.ctx.fillRect(this.frogX + 0.15*this.frogWidth,this.frogY + 0.95*this.frogHeight,5,5);
      // this.ctx.fillStyle = 'green';
      // this.ctx.fillRect(this.frogX + 0.75*this.frogWidth,this.frogY + 0.95*this.frogHeight,5,5);
        if (this.obstacles[i].type < 2 &&
            this.frogX + 0.15*this.frogWidth < this.obstacles[i].x + 0.8*this.mittensWidth &&
            this.frogX + 0.75*this.frogWidth > this.obstacles[i].x + 0.1*this.mittensWidth &&
            this.frogY < this.obstacles[i].y + this.mittensHeight &&
            this.frogY + 0.95*this.frogHeight > this.obstacles[i].y + 0.2*this.obstacleHeight
        ) {
            this.gameOver = true;     
        }
        else if (
            this.frogX + 0.15*this.frogWidth < this.obstacles[i].x + this.obstacleWidth &&
            this.frogX + this.frogWidth > this.obstacles[i].x &&
            this.frogY < this.obstacles[i].y + this.obstacleHeight &&
            this.frogHeight + this.frogY > this.obstacles[i].y
        ) {
            this.gameOver = true;
        }
    }
  }

  update(elapsed: number) {
    let sf = elapsed / 6;
    if (this.isJumping) {
        this.frogY -= this.frogSpeed * sf;
        this.frogSpeed -= this.gravity * sf;
        if (this.frogY > this.canvasHeight - 1.2*this.frogHeight) {
            this.isJumping = false;
            this.frogY = this.canvasHeight - 1.2*this.frogHeight;
            this.frogSpeed = this.initialFrogSpeed;
        }
    }
    this.scoreTimer += elapsed;

    if (this.scoreTimer > 6) { 
      this.scoreService.score += Math.floor(this.scoreTimer/6);
      this.scoreTimer = this.scoreTimer % 6;
    }
  }

  draw(currentTimestamp: number) {
    const elapsed = currentTimestamp - this.previousTimestamp;
    this.previousTimestamp = currentTimestamp;
    this.drawing = true;
    this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    if (this.gameStarted) {
        this.drawFrog(elapsed);
        if (this.gameOver) {
            this.cdr.detectChanges();
            this.drawing = false;
        } else {
            this.drawObstacle(elapsed);
            this.moveObstacle(elapsed);
            this.createObstacle();
            this.checkCollision();
            this.update(elapsed);
            this.ngZone.runOutsideAngular(() => requestAnimationFrame(() => this.draw(performance.now())));
        }
    } else {
        this.drawFrog(elapsed);
        this.ngZone.runOutsideAngular(() => requestAnimationFrame(() => this.draw(performance.now())));
    }
  }

  resetGame() {
    this.setCanvasSize();
    this.gameOver = false;
    this.gameStarted = false;
    this.isJumping = false;
    this.obstacles = new Array();
    this.scoreService.score = 0;
    this.lastObstacleX = 0;
    this.obstacleSpacing = 200 * this.scalingFactor;
    this.frogHeight = 85 * this.scalingFactor;
    this.frogWidth = 75 * this.scalingFactor;
    this.initialFrogSpeed = 9 * this.scalingFactor;
    this.frogSpeed = 9 * this.scalingFactor;
    this.frogSpriteRow = 0;
    this.frogSpriteColumn = 0;
    this.frogSpriteTimer = 0;
    this.setInitialFrogPosition();
    this.cdr.detectChanges();
    this.previousTimestamp = performance.now();
    this.draw(this.previousTimestamp);
  }

  jumpButtonTouchStart() {
    if (this.gameOver) {
      this.resetGame();
      this.gameStarted = true;
    } else {
      if (this.gameStarted && !this.isJumping) {
        this.isJumping = true;
      } else {
        this.gameStarted = true;
        this.isJumping = true;
        localStorage.setItem("gameStarted", "true");
      }
    }
    this.jumpButtonStyle.backgroundImage = `url("assets/JumpButtonPressed.PNG")`;
  }

  jumpButtonTouchEnd() {
    this.jumpButtonStyle.backgroundImage = `url("assets/JumpButton.PNG")`;
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (e.key == " " && !this.gameOver) {
        if (this.gameStarted && !this.isJumping) {
            this.isJumping = true;
        } else {
            this.gameStarted = true;
            this.isJumping = true;
            localStorage.setItem("gameStarted", "true");
        }
    } else if (e.key == " ") {
        //document.location.reload();
        // this.scoreService.score = 0;
        // this.draw();
        // this.gameStarted = true; 
        this.resetGame();
        // this.gameStarted = true; 
        // localStorage.setItem("gameStarted", "true");
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setCanvasSize();
    this.setInitialFrogPosition();
    this.frogHeight = 85 * this.scalingFactor;
    this.frogWidth = 75 * this.scalingFactor;
    this.initialFrogSpeed = 9 * this.scalingFactor;
    this.frogSpeed = 9 * this.scalingFactor;
    this.gravity = 0.2 * this.scalingFactor;
    this.isJumping = false;
    this.obstacleWidth = 70 * this.scalingFactor;
    this.obstacleHeight = 70 * this.scalingFactor;
    this.obstacleSpeed = 3 * this.scalingFactor;
    this.obstacleSpacing = 200 * this.scalingFactor;

    this.mittensWidth = 65 * this.scalingFactor;
    this.mittensHeight = 130 * this.scalingFactor; 
    //this.draw();
    if (!this.drawing) {
        this.draw(this.previousTimestamp);
    }
  }

  ngOnInit() {
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || (navigator.userAgent.includes("Macintosh") && navigator.maxTouchPoints > 1);

    this.jumpButtonStyle = {};
    this.jumpButtonStyle.backgroundImage = `url("assets/JumpButton.PNG")`;
    this.jumpButtonStyle.backgroundSize = "cover";

    this.scoreService.score = 0;
    this.scoreTimer = 0;

    this.kitchenBackground = new Image();
    this.kitchenBackground.src = "assets/kitchen/Kitchen_Holiday.PNG";

    this.fishSprite = new Image();
    this.fishSprite.src = "assets/Fish.PNG";

    this.toySprite = new Image();
    this.toySprite.src = "assets/FetchToy.PNG";

    this.frogSpriteSheet = new Image();
    this.frogSpriteSheet.src = "assets/FrogHatHiRes.PNG";

    this.mittensSpriteSheet = new Image();
    this.mittensSpriteSheet.src = "assets/MittensShadow.PNG";

    this.setCanvasSize();

    this.gameOver = false;
    this.gameStarted = false;

    this.frogSpriteWidth = 608;
    this.frogSpriteHeight = 688;
    this.frogSpriteRow = 0;
    this.frogSpriteColumn = 0;
    this.frogSpriteColumnCount = 4;
    this.frogSpriteRowCount = 2;
    this.frogSpriteInterval = 10;
    this.frogSpriteTimer = 0;

    // if (localStorage.getItem("gameStarted") === null) {
    //     localStorage.setItem("gameStarted", "false");
    // }
    // var gameStarted = localStorage.getItem("gameStarted") === true;
    this.gameOverText = "GAME OVER";
    this.restartText = "Press SPACE to Restart";
    this.frogHeight = 85 * this.scalingFactor;
    this.frogWidth = 75 * this.scalingFactor;
    
    this.setInitialFrogPosition();

    this.initialFrogSpeed = 9 * this.scalingFactor;
    this.frogSpeed = 9 * this.scalingFactor;
    this.gravity = 0.2 * this.scalingFactor;
    this.isJumping = false;
    this.obstacleWidth = 70 * this.scalingFactor;
    this.obstacleHeight = 70 * this.scalingFactor;
    this.obstacleSpeed = 3 * this.scalingFactor;
    this.obstacleSpacing = 200 * this.scalingFactor;
    this.lastObstacleX = 0;

    this.mittensWidth = 65 * this.scalingFactor;
    this.mittensHeight = 130 * this.scalingFactor;
    this.mittensSpriteWidth = 512;
    this.mittensSpriteHeight = 1024;
    this.mittensSpriteColumn = 0;
    this.mittensSpriteCount = 7;
    this.mittensSpriteInterval = 20;
    this.mittensSpriteTimer = 0;

    this.bodyFont = getComputedStyle(document.body).fontFamily;

    this.previousTimestamp = performance.now();
    this.draw(this.previousTimestamp);

  }

}
