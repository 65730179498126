import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { AuthGuard } from './auth.guard';
import { CustomerReservationsComponent } from './customer-reservations/customer-reservations.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { MySpacePageComponent } from './my-space-page/my-space-page.component'
import { MyspaceComponent } from './myspace/myspace.component';
import { ReservationGuard } from './reservation.guard';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'myspice', component: MyspaceComponent },
  { path: 'login', component: LoginComponent },
  { path: 'reservations/:dateId', component: CustomerReservationsComponent },
  // { path: 'reservations', component: CustomerReservationsComponent, canActivate: [ReservationGuard] }
  { path: 'admin', component: AdminComponent, canActivate: [AuthGuard]}
  // { path: 'admin', component: AdminComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
