import { HashLocationStrategy } from '@angular/common';
import { debugOutputAstAsTypeScript } from '@angular/compiler';
import { Component, OnInit, HostListener, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { HighScoreService } from '../high-score.service';
import { PageStateService } from '../page-state.service';
import { ScoreService } from '../score.service';

@Component({
  selector: 'app-high-score-input',
  templateUrl: './high-score-input.component.html',
  styleUrls: ['./high-score-input.component.css']
})
export class HighScoreInputComponent implements OnInit {

  @Output() notifyParent: EventEmitter<any> = new EventEmitter<any>();

  inputHeight: number;
  inputWidth: number;
  inputTop: number;
  inputLeft: number;
  lineHeight: number;
  fontSize: string;
  textShadow: number;

  caretStyle: any;

  constructor(private highScoreService: HighScoreService, private scoreService: ScoreService, private state: PageStateService, private cdr: ChangeDetectorRef) { }

  setInputDivSize() {
    this.inputHeight = window.innerWidth * 16/512;
    this.inputWidth = window.innerWidth * 40/512;
    this.inputLeft = window.innerWidth * 165/512;
    this.inputTop = window.innerWidth * 170/512;
    this.lineHeight = window.innerWidth * 15/512;
    this.fontSize = window.innerWidth * 9  /512 + "px"; 
    this.textShadow = window.innerWidth * 0.65/512;
    
    // caret style
    this.caretStyle.width = window.innerWidth * 1.5/512 + "px";
    this.caretStyle.height = window.innerWidth * 10/512 + "px";
    this.caretStyle.left = window.innerWidth * 145/512 + "px";

    if (window.innerWidth/window.innerHeight < 0.95) {
      this.inputHeight = window.innerWidth * 16/306 ;
      this.inputWidth = window.innerWidth * 40/306 ;
      this.inputLeft = window.innerWidth * 50/306 ; 
      // this.inputTop = window.innerWidth * 170/306 ;
      this.inputTop = window.innerWidth * 300/306*(1 + 0.2*(306*window.innerHeight/window.innerWidth - 322)/340) - this.state.getCounterTopCutoff();
      this.lineHeight = window.innerWidth * 15/306 ;
      this.fontSize = window.innerWidth * 9  /306  + "px"; 
      
      // caret style
      this.caretStyle.width = window.innerWidth * 1.5/306  + "px";
      this.caretStyle.height = window.innerWidth * 10/306  + "px";
      this.caretStyle.left = window.innerWidth * 145/306  + "px";

    } else if (this.state.isNarrow()) {
      this.inputHeight = window.innerHeight * 16/320;
      this.inputWidth = window.innerHeight * 40/320;
      this.inputLeft = window.innerHeight * 165/320 - this.state.getLeftCutoff();
      this.inputTop = window.innerHeight * 170/320;
      this.lineHeight = window.innerHeight * 15/320;
      this.fontSize = window.innerHeight * 9  /320 + "px";

      // caret style
      this.caretStyle.width = window.innerHeight * 1.5/320 + "px";
      this.caretStyle.height = window.innerHeight * 10/320 + "px";
      this.caretStyle.left = window.innerHeight * 145/320 + "px";
    }

    // console.log(this.caretStyle.left);

    //this.caretStyle.top = this.inputTop + "px";
  }

  submitScore(event) {
    event.preventDefault();
    const input = event.target as HTMLInputElement;
    const score = {
      name: input.value,
      score: this.scoreService.score
    };

    this.highScoreService.postHighScore(score).subscribe(() => {
      this.highScoreService.setHighScores();
      this.notifyParent.emit();
    });
  }

  inputBackspace(event) {
    const input = event.target as HTMLInputElement;

    if (input.value.length > 1 && input.value.length < 5) {
      this.caretStyle.left = parseFloat(this.caretStyle.left) - 2.10*parseInt(this.fontSize,10) + "px";
      this.cdr.detectChanges();
    } else if (input.value.length > 0) {
      this.caretStyle.left = parseFloat(this.caretStyle.left) - 1.05*parseInt(this.fontSize,10) + "px";
      this.cdr.detectChanges();
    }
  }

  onClick(event: MouseEvent) {
    // this.notifyParent.emit(); 
    const nameInput = document.getElementById("name");
    nameInput.focus();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setInputDivSize();
  }

  ngOnInit(): void {
    this.caretStyle = {};
    this.caretStyle.position = "absolute";

    this.setInputDivSize();

    const nameInput = document.getElementById("name");

    nameInput.addEventListener("input", (event) => {
        const input = event.target as HTMLInputElement;
        const maxLength = 4;
        const currentValue = input.value;
        const currentLength = currentValue.length;
        const lastCharacter = currentValue.charAt(currentLength - 1);
        
        if (currentLength > maxLength) {
            // remove the last character from the input
            input.value = currentValue.substring(0, currentLength - 2);
            input.value += lastCharacter;
        } else if (lastCharacter === " ") {
            // remove spaces from the end of the input
            input.value = currentValue.trim();
        } else if (lastCharacter.match(/^[a-zA-Z0-9]$/)){
          this.caretStyle.left = parseFloat(this.caretStyle.left) + 1.05*parseInt(this.fontSize,10) + "px";
          this.cdr.detectChanges();
        } else {
          input.value = currentValue.substring(0, currentLength - 1);
        }
    });

    nameInput.focus();
  }

}
