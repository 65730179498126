<div class="background-container">
    <div class="photo-box" [ngStyle]="photoStyle"></div>
    <div class="myspace-container" [ngStyle]="photoBoxStyle">
        <h2>Ol' Liz</h2>
    </div>
    <div class="myspace-container" style="text-align:center; justify-content: center; align-items: center;" [ngStyle]="lizIsLizBoxStyle">
        <h2>Ol' Liz is Ol' Liz</h2>
    </div>
    <div class="myspace-container" [ngStyle]="contactBoxStyle">
        <h2>Contacting Ol' Liz:</h2><br>
        Email: CouchCafe@lizlaugh.love<br><br>
        Instagram: <a href="https://www.instagram.com/buckskin_scallion" target="_blank">@buckskin_scallion</a><br><br>
        Website: <a href="https://lizlaugh.love" target="_blank">lizlaugh.love</a>
    </div>
    <div class="myspace-container" [ngStyle]="bioBoxStyle">
        <h2>Ol' Liz's Blurbs:</h2>
        <h3>About Me:</h3>
        Founder and head chef of Couch Cafe with a cooking obsession. From the comfort staples of my home state of Oklahoma like country fried steak and onion burgers, to the many regional niches of Italian cuisine and anywhere in between, I live and breathe food.<br>I got two shrimps inside me<br>and they won't stop COOKIN. 
        <h3>Who I'd Like to Meet:</h3>
        Matty Matheson
    </div>
</div>

