import { Component, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { GridService } from '../grid.service';

enum ButtonType {
  Empty,
  Table,
  ChairAvailable,
  ChairReserved,
  Disabled
}

@Component({
  selector: 'app-table-design-grid',
  templateUrl: './table-design-grid.component.html',
  styleUrls: ['./table-design-grid.component.css']
})
export class TableDesignGridComponent implements OnInit {
  @Input() grid: ButtonType[][] = [];
  numRows: number;
  numCols: number;
  chairCount = 0;
  selectedOption: ButtonType = ButtonType.Empty;
  gridContainerStyle: any;
  gridStyle: any;
  buttonStyle: any;
  optionStyle: any;
  optionButtonStyle: any;
  @Input() date: Date;

  constructor(private gridService: GridService) { 
    this.numRows = this.gridService.numRows;
    this.numCols = this.gridService.numCols;
    this.gridContainerStyle = {};
    this.gridStyle = {};
    this.buttonStyle = {};
    this.optionStyle = {};
    this.optionButtonStyle = {};
  }

  ngOnInit(): void {
    this.initializeGrid();
  }

  initializeGrid() {
    this.getGridContainerStyles();
    this.getGridStyles();
    this.getButtonStyles();
    this.getOptionStyles();
    this.getOptionButtonStyles();
    if (this.grid.length === 0) {
      for (let i = 0; i < this.numRows; i++) {
        this.grid[i] = [];
        for (let j = 0; j < this.numCols; j++) {
          if (i > 1 || j < 2) {
            this.grid[i][j] = ButtonType.Empty;
          } else {
            this.grid[i][j] = ButtonType.Disabled;
          }
        }
      }
    } 
  }

  onButtonClick(row: number, col: number) {
    if (this.selectedOption === ButtonType.ChairReserved || this.selectedOption === ButtonType.ChairAvailable) {
      this.chairCount++;
    }
    if (this.grid[row][col] === ButtonType.ChairReserved || this.grid[row][col] === ButtonType.ChairAvailable) {
      this.chairCount--;
    }
    if (this.grid[row][col] !== ButtonType.Disabled) {
      this.grid[row][col] = this.selectedOption;
    }
    
  }

  selectOption(selection: number) {
    switch (selection) {
      case 1:
        this.selectedOption = ButtonType.Empty;
        break;
      case 2:
        this.selectedOption = ButtonType.Table;
        break;
      case 3:
        this.selectedOption = ButtonType.ChairReserved;
        break;
      case 4:
        this.selectedOption = ButtonType.ChairAvailable;
        break;
      default:
        this.selectedOption = ButtonType.Empty;
    }
  }

  isOptionSelected(button: number) {
    switch (button) {
      case 1:
        return this.selectedOption === ButtonType.Empty;
      case 2:
        return this.selectedOption === ButtonType.Table;
      case 3:
        return this.selectedOption === ButtonType.ChairReserved;
      case 4:
        return this.selectedOption === ButtonType.ChairAvailable;
      default:
        return false;
    }
  }

  getButtonClass(button: ButtonType): string {
    switch (button) {
      case ButtonType.Empty:
        return 'empty';
      case ButtonType.Table:
        return 'table';
      case ButtonType.ChairAvailable:
      case ButtonType.ChairReserved:
        return 'chair';
      case ButtonType.Disabled:
        return 'disabled';
      default:
        return '';
    }
  }

  getButtonText(button: ButtonType): string {
    // switch (button) {
    //   case ButtonType.Empty:
    //     return 'E';
    //   case ButtonType.Table:
    //     return 'T';
    //   case ButtonType.Chair:
    //     return 'C';
    //   default:
    //     return '';
    // }
    return '';
  }

  getGridContainerStyles() {
    this.gridContainerStyle.width = window.innerWidth * 262/512 + "px";
    this.gridContainerStyle.height = window.innerWidth * 202/512 + "px";
    this.gridContainerStyle.left = window.innerWidth * 30/512 + "px";
  }

  getGridStyles() {
    this.gridStyle.width = window.innerWidth * 180/512 + "px";
    this.gridStyle.left = window.innerWidth * 81/512 + "px";
    this.gridStyle.top = window.innerWidth * 1/512 + "px";
  }

  getButtonStyles() {
    this.buttonStyle.width = window.innerWidth * 20/512 + "px";
    this.buttonStyle.height = window.innerWidth * 20/512 + "px";
  }

  getButtonImage(row: number, col: number) {
    let buttonStyleImage = this.buttonStyle;
    if (this.grid[row][col] === ButtonType.ChairAvailable) {
      if (row > 0 && this.grid[row - 1][col] === ButtonType.Table) {
        buttonStyleImage.backgroundImage = `url(assets/reservations/ChairAvailable_Up.PNG)`;
      } else if (row < this.numRows - 1 && this.grid[row + 1][col] === ButtonType.Table) {
        buttonStyleImage.backgroundImage = `url(assets/reservations/ChairAvailable_Down.PNG)`;
      } else if (col > 0 && this.grid[row][col - 1] === ButtonType.Table) {
        buttonStyleImage.backgroundImage = `url(assets/reservations/ChairAvailable_Left.PNG)`;
      } else if (col < this.numCols - 1 && this.grid[row][col + 1] === ButtonType.Table) {
        buttonStyleImage.backgroundImage = `url(assets/reservations/ChairAvailable_Right.PNG)`;
      } else { 
        buttonStyleImage.backgroundImage = `url(assets/reservations/ChairAvailable_Left.PNG)`;
      }
    } else if (this.grid[row][col] === ButtonType.ChairReserved) {
      if (row > 0 && this.grid[row - 1][col] === ButtonType.Table) {
        buttonStyleImage.backgroundImage = `url(assets/reservations/ChairReserved_Up.PNG)`;
      } else if (row < this.numRows - 1 && this.grid[row + 1][col] === ButtonType.Table) {
        buttonStyleImage.backgroundImage = `url(assets/reservations/ChairReserved_Down.PNG)`;
      } else if (col > 0 && this.grid[row][col - 1] === ButtonType.Table) {
        buttonStyleImage.backgroundImage = `url(assets/reservations/ChairReserved_Left.PNG)`;
      } else if (col < this.numCols - 1 && this.grid[row][col + 1] === ButtonType.Table) {
        buttonStyleImage.backgroundImage = `url(assets/reservations/ChairReserved_Right.PNG)`;
      } else { 
        buttonStyleImage.backgroundImage = `url(assets/reservations/ChairReserved_Left.PNG)`;
      }
    } else if (this.grid[row][col] === ButtonType.Table) {
      buttonStyleImage.backgroundImage = `url(assets/reservations/Table.PNG)`;
    } else if (this.grid[row][col] === ButtonType.Empty) {
      buttonStyleImage.backgroundImage = `url(assets/reservations/EmptySquare.PNG)`;
    } else if (this.grid[row][col] === ButtonType.Disabled) {
      buttonStyleImage.backgroundImage = "none";
    }
    
    return buttonStyleImage;
  }

  getOptionStyles() {
    this.optionStyle.left = window.innerWidth * 135/512 + "px";
    this.optionStyle.top = window.innerWidth * 5/512 + "px";
    this.optionStyle.width = window.innerWidth * 124/512 + "px";
  }
  
  getOptionButtonStyles () {
    this.optionButtonStyle.height = window.innerWidth * 20/512 + "px";
    this.optionButtonStyle.width = this.optionButtonStyle.height;
    this.optionButtonStyle.marginRight = window.innerWidth * 11/512 + "px";
  }

  public getGrid() {
    return this.grid;
  }

  public displayInputGrid(grid: any[][]) {
    this.grid = grid;
  }

  public resetGrid() {
    this.grid = [];
    this.initializeGrid();
  }

  public setDate(date: any) {
    this.date = date;
  }

  public getGridJsonObject(): any {
    const date = {
      date: this.date,
      soldOut: false,
      squares: []
    }
    let square;
    for (let i = 0; i < this.numRows; i++){
      for (let j = 0; j < this.numCols; j++) {
        switch (this.grid[i][j]) {
          case ButtonType.Table:
            square = {
              row: i,
              column: j,
              type: 'T',
              reserved: false
            };
            date.squares.push(square);
            break;
          case ButtonType.ChairAvailable:
            square = {
              row: i,
              column: j,
              type: 'O',
              reserved: false
            };
            date.squares.push(square);
            break;
          case ButtonType.ChairReserved:
            square = {
              row: i,
              column: j,
              type: 'C',
              reserved: true
            };
            date.squares.push(square);
            break;
          default:
            break;
        }
      }
    }
    return date;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.initializeGrid();
  }

}
