import { Component, HostListener, OnInit } from '@angular/core';
import { PageStateService } from '../page-state.service';

@Component({
  selector: 'app-my-space-page',
  templateUrl: './my-space-page.component.html',
  styleUrls: ['./my-space-page.component.css']
})
export class MySpacePageComponent implements OnInit {
  photoBoxStyle: any;
  photoStyle: any;
  lizIsLizBoxStyle: any;
  contactBoxStyle: any;
  bioBoxStyle: any;

  aspectRatio: number;

  constructor(private state: PageStateService) { }

  ngOnInit(): void {
    this.photoBoxStyle = {};
    this.photoStyle = {};
    this.lizIsLizBoxStyle = {};
    this.contactBoxStyle = {};
    this.bioBoxStyle = {};

    this.setStyles();
    this.aspectRatio = window.innerWidth/window.innerHeight;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setStyles();
    this.aspectRatio = window.innerWidth/window.innerHeight; 
  }

  setStyles() {
    this.photoBoxStyle.width = window.innerHeight * 4/8 + "px";
    this.photoBoxStyle.height = window.innerHeight * 3/8 + "px";
    this.photoBoxStyle.left = window.innerWidth * 1/20 + "px";
    this.photoBoxStyle.top = window.innerHeight * 1/8 + "px";
    this.photoBoxStyle.borderWidth = window.innerHeight * 1.25/512 + "px";
    this.photoBoxStyle.padding = window.innerHeight * 10/512 + "px";
    this.photoBoxStyle.paddingTop = window.innerHeight * 2/512 + "px";
    this.photoBoxStyle.fontSize = window.innerHeight * 8/512 + "px";
    this.photoBoxStyle.lineHeight = window.innerHeight * 10/512 + "px";

    this.photoStyle.width = window.innerHeight * 78.235/320 + "px";
    this.photoStyle.height = window.innerHeight * 95/320 + "px";
    this.photoStyle.left = window.innerWidth * 1/15 + "px";
    this.photoStyle.top = window.innerHeight * 1/5 + "px";
    
    this.lizIsLizBoxStyle.width = window.innerWidth * 3/8 + "px";
    this.lizIsLizBoxStyle.height = window.innerHeight * 1/16 + "px";
    this.lizIsLizBoxStyle.left = window.innerWidth * (5/9 - 1/30) + "px";
    this.lizIsLizBoxStyle.top = window.innerHeight * 1/8 + "px";
    this.lizIsLizBoxStyle.padding = window.innerHeight * 10/512 + "px";
    this.lizIsLizBoxStyle.paddingTop = window.innerHeight * 10/512 + "px";

    this.contactBoxStyle.width = window.innerWidth * 3/8 + "px";
    this.contactBoxStyle.height = window.innerHeight * 3/16 + "px";
    this.contactBoxStyle.left = window.innerWidth * 1/20 + "px";
    this.contactBoxStyle.top = window.innerHeight * 5/8 + "px";
    this.contactBoxStyle.padding = window.innerHeight * 10/512 + "px";
    this.contactBoxStyle.paddingTop = window.innerHeight * 2/512 + "px";

    this.bioBoxStyle.width = window.innerWidth * 3/8 + "px";
    this.bioBoxStyle.height = window.innerHeight * 4/8 + "px";
    this.bioBoxStyle.left = window.innerWidth * (5/9 - 1/30) + "px";
    this.bioBoxStyle.top = window.innerHeight * 5/16 + "px";
    this.bioBoxStyle.padding = window.innerHeight * 10/512 + "px";
    this.bioBoxStyle.paddingTop = window.innerHeight * 2/512 + "px";

    if (window.innerWidth/window.innerHeight < 0.95) {
      this.photoBoxStyle.fontSize = window.innerWidth * 5/306 + "px";
      this.photoBoxStyle.lineHeight = window.innerWidth * 5.7/306 + "px";
      this.photoBoxStyle.height = window.innerWidth * 125/306 + "px";
      this.photoBoxStyle.width = window.innerWidth * 125/306 + "px";
      this.photoStyle.width = window.innerWidth * 70/306 + "px";
      this.photoStyle.height = window.innerWidth * 85/306 + "px";
      this.photoBoxStyle.borderWidth = window.innerWidth * 1.25/306 + "px";
    } else if (this.state.isNarrow()) {
      this.photoBoxStyle.width = window.innerWidth * 95/306 + "px";
      this.photoBoxStyle.fontSize = window.innerHeight * 5/320 + "px";
      this.photoBoxStyle.lineHeight = window.innerHeight * 7/320 + "px";
      this.photoBoxStyle.borderWidth = window.innerHeight * 1.25/320 + "px";
    }

    this.lizIsLizBoxStyle.fontSize = this.photoBoxStyle.fontSize;
    this.lizIsLizBoxStyle.lineHeight = this.photoBoxStyle.lineHeight;
    this.lizIsLizBoxStyle.borderWidth = this.photoBoxStyle.borderWidth;
    this.contactBoxStyle.fontSize = this.photoBoxStyle.fontSize;
    this.contactBoxStyle.lineHeight = this.photoBoxStyle.lineHeight;
    this.contactBoxStyle.borderWidth = this.photoBoxStyle.borderWidth;
    this.bioBoxStyle.fontSize = this.photoBoxStyle.fontSize;  
    this.bioBoxStyle.lineHeight = this.photoBoxStyle.lineHeight;
    this.bioBoxStyle.borderWidth = this.photoBoxStyle.borderWidth;
    this.photoStyle.borderWidth = this.photoBoxStyle.borderWidth;
    this.photoStyle.top = parseInt(this.photoBoxStyle.top,10) + 25/125*parseInt(this.photoBoxStyle.height,10) + "px";
  }

}
