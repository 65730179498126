import { Injectable } from '@angular/core';
import * as bcrypt from 'bcryptjs';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {
  private sR = 10;
  public isAuthenticated = false;

  hashPassword(password:string): Promise<string> {
    return bcrypt.hash(password, this.sR);
  }

  comparePasswords(plainPassword: string, hashedPassword: string): Promise<string> {
    return bcrypt.compare(plainPassword, hashedPassword);
  }

  constructor() { }
}
