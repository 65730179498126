import { Component, OnInit, HostListener, NgZone, ChangeDetectorRef } from '@angular/core';
import { PageStateService } from '../page-state.service';
import { ScoreService } from '../score.service';

@Component({
  selector: 'app-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.css']
})
export class ScoreComponent implements OnInit {

  scoreStyle: any;

  constructor(public score: ScoreService, private state: PageStateService, private ngZone: NgZone, private cdr: ChangeDetectorRef) { }

  setScoreStyle() {
    var textShadow = window.innerWidth * 0.65/512;
    this.scoreStyle.left = window.innerWidth * 280/512 + "px";
    this.scoreStyle.top = window.innerWidth * 100/512 + "px";
    this.scoreStyle.fontSize = window.innerWidth * 9/512 + "px";

    if (window.innerWidth/window.innerHeight < 0.95) {
      this.scoreStyle.left = window.innerWidth * 165/306 + "px";
      this.scoreStyle.top = window.innerWidth * 205/306 - this.state.getTopCutoff() + "px";
      this.scoreStyle.fontSize = window.innerWidth * 9/306 + "px";
      textShadow = window.innerWidth * 0.65/306;

    } else if (this.state.isNarrow()) {
      this.scoreStyle.left = window.innerHeight * 280/320 - this.state.getLeftCutoff() + "px";
      this.scoreStyle.top = window.innerHeight * 100/320 + "px";
      this.scoreStyle.fontSize = window.innerHeight * 9/320 + "px";
      textShadow = window.innerHeight * 0.65/320;
    }

    this.scoreStyle.textShadow = '-' + textShadow + 'px -' + textShadow + 'px 0 #fff, ' + textShadow + 'px -' + textShadow + 'px 0 #fff, -' + textShadow + 'px ' + textShadow + 'px 0 #fff, ' + textShadow + 'px ' + textShadow + 'px 0 #fff';
  }

  getScore() {
    this.cdr.detectChanges();
    this.ngZone.runOutsideAngular(() => requestAnimationFrame(() => this.getScore()));
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setScoreStyle();
  }

  ngOnInit(): void {
    this.scoreStyle = {};

    this.setScoreStyle();
    this.getScore();
  }

}
