<button *ngIf="!showForm" (click)="showFormOnClick()" [ngStyle]="buttonStyle">Add Future Event</button>

<form *ngIf="showForm" [formGroup]="dateForm">
    <div class="form-container" [ngStyle]="formStyle">
      <label for="eventName">Event Name:</label>
      <input type="text" autocomplete="off" id="eventName" formControlName="eventName" [ngStyle]="inputStyle"required>
      <br><br>
      <label for="eventPrice">Price PP:$</label>
      <input type="number" autocomplete="off" id="eventPrice" formControlName="eventPrice" [ngStyle]="inputStyle"required>
      <br><br>
      <label for="primaryDate">Select Date:</label>
      <input type="datetime-local" id="dateInput" formControlName="selectedDate" [ngStyle]="dateInputStyle"><br><br>

      <button type="button" (click)="addDate()" [ngStyle]="buttonStyle">Add Date To Event</button><br><br>
      Date(s):<br><br>
      <div *ngFor="let date of dateList; let i = index">
        <button type="button" (click)="selectDate(date.date)" [ngClass]="{ 'selected': date.date === selectedDate.date }">{{ date.date }}</button>
        <button type="button" (click)="removeDate(date.date)">Remove</button><br><br>
      </div><br>
      <!-- <div formArrayName="additionalDates">
        <div *ngFor="let dateControl of getAdditionalDatesControls(); let i = index">
            <label for="additionalDate{{i}}">Additional Date {{i + 1}}:</label>
            <input type="datetime-local" [id]="'additionalDate' + i" formControlName="{{i}}" [formControl]="castToFormControl(dateControl)">
            <input type="datetime-local" [id]="'additionalDate' + i" formControlName="{{i}}" required>
            <label>
              <input type="checkbox" [(ngModel)]="usePreviousSeatingChart" />
              Use Previous Seating Chart
            </label>
            <button type="button" (click)="removeAdditionalDate(i)">Remove</button>
            <app-table-design-grid [date]="dateForm.get('additionalDates')?.value[i]"></app-table-design-grid>
        </div>
      </div> -->
      <button type="submit" (click)="addFutureEvent()" [ngStyle]="buttonStyle">Submit</button><br><br>
      <button (click)="hideFormOnClick()" [ngStyle]="buttonStyle">Cancel</button><br><br>
    </div>
    
    <!-- <app-table-design-grid [date]="dateForm.get('primaryDate').value" [grid]="newDate ? [] : grid"></app-table-design-grid> -->
    <app-table-design-grid [date]="newDate ? selectedDate.date : dateForm.get('primaryDate').value" [grid]="newDate ? [] : selectedDate.grid"></app-table-design-grid>
    
    <br>
      <!-- Button to add additional dates -->
    
</form>
