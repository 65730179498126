import { Component, OnInit, HostListener } from '@angular/core';
import { PageStateService } from '../page-state.service';

@Component({
  selector: 'app-start-game',
  templateUrl: './start-game.component.html',
  styleUrls: ['./start-game.component.css']
})
export class StartGameComponent implements OnInit {

  isMobile: boolean;

  mobileText: string = `Press JUMP<br>to play Liz's<br>Leap Frogs`;
  nativeText: string = `Press SPACE<br>to play Liz's<br>Leap Frogs`;

  divStyle: any;

  constructor(private state: PageStateService) { }

  setDivStyle() {
    var textShadow = window.innerWidth * 0.65/512;
    this.divStyle.left = window.innerWidth * 275/512 + "px";
    this.divStyle.top = window.innerWidth * 86/512 + "px";
    this.divStyle.fontSize = window.innerWidth * 8/512 + "px";
    this.divStyle.lineHeight = window.innerWidth * 12/512 + "px";

    if (window.innerWidth/window.innerHeight < 0.95) {
      this.divStyle.left = window.innerWidth * 164/306 + "px";
      this.divStyle.top = window.innerWidth * 190/306 - this.state.getTopCutoff() + "px";
      this.divStyle.fontSize = window.innerWidth * 7/306 + "px";
      this.divStyle.lineHeight = window.innerWidth * 12/306 + "px";
      textShadow = window.innerWidth * 0.65/306;

    } else if (this.state.isNarrow()) {
      this.divStyle.left = window.innerHeight * 275/320 - this.state.getLeftCutoff() + "px";
      this.divStyle.top = window.innerHeight * 86/320 + "px";
      this.divStyle.fontSize = window.innerHeight * 8/320 + "px";
      this.divStyle.lineHeight = window.innerHeight * 12/320 + "px";
      textShadow = window.innerHeight * 0.65/320;
    }

    this.divStyle.textShadow = '-' + textShadow + 'px -' + textShadow + 'px 0 #000, ' + textShadow + 'px -' + textShadow + 'px 0 #000, -' + textShadow + 'px ' + textShadow + 'px 0 #000, ' + textShadow + 'px ' + textShadow + 'px 0 #000';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setDivStyle();
  }

  ngOnInit(): void {
    this.divStyle = {};

    this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    this.setDivStyle();
  }

}
