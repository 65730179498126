import { Injectable, HostListener } from '@angular/core';
import { Subject } from 'rxjs';

export enum PageState {
  HOME,
  FUTURE,
  PAST,
  ABOUT
}

@Injectable({
  providedIn: 'root'
})
export class PageStateService {

  public currentState: PageState = PageState.HOME;
  private currentStateSubject = new Subject<PageState>();
  private narrow: boolean;
  private leftCutoff: number;
  private topCutoff: number;
  private counterTopCutoff: number;

  setCurrentState(state: PageState) {
    this.currentState = state;
    this.currentStateSubject.next(state);
  }

  getCurrentStateObservable() {
    return this.currentStateSubject.asObservable();
  }

  getLeftCutoff() {
    this.leftCutoff = (window.innerHeight * 512/320 - window.innerWidth)/3;
    if (window.innerWidth/window.innerHeight < 1.18) {
      this.leftCutoff = window.innerHeight * 422/320 - window.innerWidth;
    }
    return this.leftCutoff;
  }

  getTopCutoff() {
    this.topCutoff = 107*(1 - (window.innerHeight/window.innerWidth*306 - 306)/356)/306*window.innerWidth;
    return this.topCutoff;
  }

  getCounterTopCutoff() {
    this.counterTopCutoff = 126*(1 - 0.55*(306*window.innerHeight/window.innerWidth - 322)/340)/306*window.innerWidth;
    return this.counterTopCutoff;
  }

  isNarrow() {
    this.narrow = window.innerWidth / window.innerHeight < 512/320;
    return this.narrow;
  }

  constructor() { }
}
