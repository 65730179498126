
<div class="design-grid-container" [ngStyle]="gridContainerStyle">
    <div class="option-buttons" [ngStyle]="optionStyle">
        <button class="option-button" (click)="selectOption(1)" [ngStyle]="optionButtonStyle" [class.selected]="isOptionSelected(1)"></button>
        <button class="option-button" (click)="selectOption(2)" [ngStyle]="optionButtonStyle" [class.selected]="isOptionSelected(2)"></button>
        <button class="option-button" (click)="selectOption(3)" [ngStyle]="optionButtonStyle" [class.selected]="isOptionSelected(3)"></button>
        <button class="option-button" (click)="selectOption(4)" [ngStyle]="optionButtonStyle" [class.selected]="isOptionSelected(4)"></button>
        <!-- <button (click)="selectOption()" [innerHtml]="getButtonText(selectedOption)" [ngClass]="getButtonClass(selectedOption)">Empty</button> -->
    </div>
    <div class="grid" [ngStyle]="gridStyle">
        <ng-container *ngFor="let row of grid; let i = index">
            <div class="row">
                <button *ngFor="let button of row; let j = index" (click)="onButtonClick(i, j)" [ngClass]="getButtonClass(button)" [ngStyle]="getButtonImage(i, j)">
                {{ getButtonText(button) }}
                </button>
            </div>
        </ng-container><br>
        <div class="chair-count">
            Number of Chairs: {{ chairCount }}
        </div>
    </div>    
</div>
