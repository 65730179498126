import { Component, OnInit, HostListener, ChangeDetectorRef, NgZone, ViewChild, ElementRef } from '@angular/core';
import { PageStateService, PageState } from '../page-state.service';

@Component({
  selector: 'app-our-story-book',
  templateUrl: './our-story-book.component.html',
  styleUrls: ['./our-story-book.component.css']
})
export class OurStoryBookComponent implements OnInit {

  public PageState = PageState;

  @ViewChild('book') book: ElementRef;

  bookStyle: any;
  bookOpenedStyle: any;
  bookText: string;

  books: string[] = ["assets/book/AboutUsBook_1.PNG", "assets/book/AboutUsBook_2.PNG", "assets/book/AboutUsBook_3.PNG", "assets/book/AboutUsBook_4.PNG", "assets/book/AboutUsBook_5.PNG",
                    "assets/book/AboutUsBook_6.PNG", "assets/book/AboutUsBook_7.PNG", "assets/book/AboutUsBook_8.PNG", "assets/book/AboutUsBook_9.PNG", "assets/book/AboutUsBook_10.PNG"];
  bookImages: any[] = [];
  currentFrame: number;

  pageOpened: boolean;

  constructor(private state: PageStateService, private cdr: ChangeDetectorRef, private ngZone: NgZone) { }

  setBookSize() {
    let aspectRatio = window.innerWidth/window.innerHeight;
    this.bookStyle.width = window.innerWidth * 113/512 + "px";
    this.bookStyle.height = window.innerWidth * 171/512 + "px";
    this.bookStyle.left = window.innerWidth * 303/512 + "px";
    this.bookStyle.top = window.innerWidth * 24/512 + "px";
    this.bookOpenedStyle.width = window.innerWidth * 113/512 + "px";
    this.bookOpenedStyle.height = window.innerWidth * 171/512 + "px";
    this.bookOpenedStyle.left = window.innerWidth * 275/512 + "px";
    this.bookOpenedStyle.top = window.innerWidth * 15/512 + "px";
    this.bookOpenedStyle.fontSize = window.innerWidth * 6.5/512 + "px";
    this.bookOpenedStyle.paddingTop = window.innerWidth * 10/512 + "px";
    this.bookOpenedStyle.paddingLeft = window.innerWidth * 8/512 + "px";
    this.bookOpenedStyle.paddingRight = window.innerWidth * 12/512 + "px";
    this.bookOpenedStyle.paddingBottom = window.innerWidth * 17/512 + "px";
    this.bookOpenedStyle.lineHeight = window.innerWidth * 10/512 + "px";
    if (aspectRatio < 0.95) {
      this.bookStyle.width = window.innerWidth * 113/306 + "px";
      this.bookStyle.height = window.innerWidth * 171/306 + "px";
      this.bookStyle.left = window.innerWidth * 187/306 + "px";
      this.bookStyle.top = window.innerWidth * 128/306 - this.state.getTopCutoff() + "px";
      this.bookOpenedStyle.width = window.innerWidth * 113/306*(1 + 0.4*(306*window.innerHeight/window.innerWidth - 322)/340) + "px";
      this.bookOpenedStyle.height = window.innerWidth * 171/306*(1 + 0.4*(306*window.innerHeight/window.innerWidth - 322)/340) + "px";
      this.bookOpenedStyle.left = window.innerWidth * 157/306*(1 - 0.5*(306*window.innerHeight/window.innerWidth - 322)/340) + "px";
      this.bookOpenedStyle.top = window.innerWidth * 119/306*(1 - 0.5*(306*window.innerHeight/window.innerWidth - 322)/340) - this.state.getTopCutoff() + "px";
      this.bookOpenedStyle.fontSize = window.innerWidth * 6.3/306*(1 + 0.4*(306*window.innerHeight/window.innerWidth - 322)/340) + "px";
      this.bookOpenedStyle.paddingTop = window.innerWidth * 10/306 + "px";
      this.bookOpenedStyle.paddingLeft = window.innerWidth * 8/306 + "px";
      this.bookOpenedStyle.paddingRight = window.innerWidth * 12/306 + "px";
      this.bookOpenedStyle.paddingBottom = window.innerWidth * 17/306 + "px";
      this.bookOpenedStyle.lineHeight = window.innerWidth * 10/306*(1 + 0.5*(306*window.innerHeight/window.innerWidth - 322)/340) + "px";
    } else if (aspectRatio < 512/320) {
      this.bookStyle.width = window.innerHeight * 113/320 + "px";
      this.bookStyle.height = window.innerHeight * 171/320 + "px";
      this.bookStyle.left = window.innerHeight * 303/320 - this.state.getLeftCutoff() + "px";
      this.bookStyle.top = window.innerHeight * 24/320 + "px";
      this.bookOpenedStyle.width = window.innerHeight * 113/320 + "px";
      this.bookOpenedStyle.height = window.innerHeight * 171/320 + "px";
      this.bookOpenedStyle.left = window.innerHeight * 275/320 - this.state.getLeftCutoff() + "px";
      this.bookOpenedStyle.top = window.innerHeight * 15/320 + "px";
      this.bookOpenedStyle.fontSize = window.innerHeight * 6.5/320 + "px";
      this.bookOpenedStyle.paddingTop = window.innerHeight * 10/320 + "px";
      this.bookOpenedStyle.paddingLeft = window.innerHeight * 8/320 + "px";
      this.bookOpenedStyle.paddingRight = window.innerHeight * 12/320 + "px";
      this.bookOpenedStyle.paddingBottom = window.innerHeight * 17/320 + "px";
      this.bookOpenedStyle.lineHeight = window.innerHeight * 10/320 + "px";
    }
    this.cdr.detectChanges();
  }

  openPage() {
    if (this.currentFrame < this.bookImages.length) {
      this.bookStyle.height = parseInt(this.bookStyle.height, 10) + 1/4*(parseInt(this.bookOpenedStyle.height) - parseInt(this.bookStyle.height, 10)) + "px";
      this.bookStyle.width = parseInt(this.bookStyle.width, 10) + 1/4*(parseInt(this.bookOpenedStyle.width) - parseInt(this.bookStyle.width, 10)) + "px";
      this.bookStyle.left = parseInt(this.bookStyle.left, 10) + 1/4*(parseInt(this.bookOpenedStyle.left) - parseInt(this.bookStyle.left, 10)) + "px";
      this.bookStyle.top = parseInt(this.bookStyle.top, 10) + 1/4*(parseInt(this.bookOpenedStyle.top) - parseInt(this.bookStyle.top, 10)) + "px";
      this.bookStyle.backgroundImage = `url(${this.bookImages[this.currentFrame].src})`;
      this.bookOpenedStyle.backgroundImage = `url(${this.bookImages[this.currentFrame].src})`;
      this.currentFrame++;
      setTimeout(() => {
        this.ngZone.runOutsideAngular(() => requestAnimationFrame(() => this.openPage()));
      }, 50);
    } else {
      this.pageOpened = true;
      this.setBookSize();
      this.bookText = "Welcome to the kitchen! Head chef Liz started her career in industrial safety for [insert evil megacorp] but hated that corporate shit, so now she does Couch Cafe.";
    }
    this.cdr.detectChanges();
  }

  closePage() {
    this.bookText = "";
    if (this.currentFrame >= 0) {
      this.bookOpenedStyle.height = parseInt(this.bookOpenedStyle.height, 10) + 1/4*(parseInt(this.bookStyle.height) - parseInt(this.bookOpenedStyle.height, 10)) + "px";
      this.bookOpenedStyle.width = parseInt(this.bookOpenedStyle.width, 10) + 1/4*(parseInt(this.bookStyle.width) - parseInt(this.bookOpenedStyle.width, 10)) + "px";
      this.bookOpenedStyle.left = parseInt(this.bookOpenedStyle.left, 10) + 1/4*(parseInt(this.bookStyle.left) - parseInt(this.bookOpenedStyle.left, 10)) + "px";
      this.bookOpenedStyle.top = parseInt(this.bookOpenedStyle.top, 10) + 1/4*(parseInt(this.bookStyle.top) - parseInt(this.bookOpenedStyle.top, 10)) + "px";
      this.bookOpenedStyle.backgroundImage = `url(${this.bookImages[this.currentFrame].src})`;
      this.currentFrame--;
      setTimeout(() => {
        this.ngZone.runOutsideAngular(() => requestAnimationFrame(() => this.closePage()));
      }, 50);
    } else {
      this.pageOpened = false;
      this.state.setCurrentState(PageState.HOME);
    }
    this.cdr.detectChanges();
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (this.state.currentState === PageState.ABOUT && !this.book.nativeElement.contains(event.target) && this.pageOpened) {
      this.currentFrame = this.bookImages.length - 1;
      this.closePage();
      this.cdr.detectChanges();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setBookSize();
  }

  ngOnInit(): void {
    this.currentFrame = 0;

    for (let i = 0; i < this.books.length; i++) {
      this.bookImages[i] = new Image();
      this.bookImages[i].src = this.books[i];
    }

    this.bookStyle = {};
    this.bookOpenedStyle = {};
    this.bookStyle.backgroundImage = `url(${this.bookImages[0].src})`;
    this.bookOpenedStyle.backgroundImage = `url(${this.bookImages[0].src})`;

    this.pageOpened = false;

    this.setBookSize();

    this.openPage();
  }

}
