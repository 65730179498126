import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HighScoresComponent } from './high-scores/high-scores.component';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class HighScoreService {
  baseUrl: string;
  endpoint: string;

  private highScoreComponent: HighScoresComponent;

  constructor(private http: HttpClient, private urlService: UrlService) { 
    this.baseUrl = this.urlService.getBaseUrl();
    this.endpoint = "/CouchCafe/api/highscores";
  }

  registerHighScoresComponent(component: HighScoresComponent) {
    this.highScoreComponent = component;
  }

  public getHighScores() {
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Access-Control-Allow-Origin': '*'
    // });
    // return this.http.get(this.baseUrl + this.endpoint, { headers });
    return this.http.get(this.baseUrl + this.endpoint, { });
  }

  public setHighScores() {
    this.highScoreComponent.setHighScoreText();
  }

  public postHighScore(highScore: any) {
    return this.http.post(this.baseUrl + this.endpoint, highScore, {});
  }
}
