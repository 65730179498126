import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FrogGameComponent } from './frog-game/frog-game.component';
import { HighScoreInputComponent } from './high-score-input/high-score-input.component';
import { HighScoresComponent } from './high-scores/high-scores.component';
import { MenuButtonsComponent } from './menu-buttons/menu-buttons.component';
import { MenuCardsComponent } from './menu-cards/menu-cards.component';
import { OurStoryBookComponent } from './our-story-book/our-story-book.component';
import { FutureDatesCalendarComponent } from './future-dates-calendar/future-dates-calendar.component';
import { GameOverComponent } from './game-over/game-over.component';
import { ScoreComponent } from './score/score.component';
import { StartGameComponent } from './start-game/start-game.component';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { FormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { NewEventInputComponent } from './new-event-input/new-event-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TableDesignGridComponent } from './table-design-grid/table-design-grid.component';
import { DatePipe } from '@angular/common';
import { CustomerReservationsComponent } from './customer-reservations/customer-reservations.component';
import { TableReservationGridComponent } from './table-reservation-grid/table-reservation-grid.component';
import { ReservationFailedComponent } from './reservation-failed/reservation-failed.component';
import { AdminReservationGridComponent } from './admin-reservation-grid/admin-reservation-grid.component';
import { AdminFutureDatesCalendarComponent } from './admin-future-dates-calendar/admin-future-dates-calendar.component';
import { MySpacePageComponent } from './my-space-page/my-space-page.component';
import { MyspaceComponent } from './myspace/myspace.component';

@NgModule({
  declarations: [
    AppComponent,
    FrogGameComponent,
    HighScoreInputComponent,
    HighScoresComponent,
    MenuButtonsComponent,
    MenuCardsComponent,
    OurStoryBookComponent,
    FutureDatesCalendarComponent,
    GameOverComponent,
    ScoreComponent,
    StartGameComponent,
    LoginComponent,
    AdminComponent,
    HomeComponent,
    NewEventInputComponent,
    TableDesignGridComponent,
    CustomerReservationsComponent,
    TableReservationGridComponent,
    ReservationFailedComponent,
    AdminReservationGridComponent,
    AdminFutureDatesCalendarComponent,
    MySpacePageComponent,
    MyspaceComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
