import { Component, HostListener, OnInit } from '@angular/core';
import { FutureEventsService } from '../future-events.service';
import { ReservationService } from '../reservation.service';

@Component({
  selector: 'app-admin-future-dates-calendar',
  templateUrl: './admin-future-dates-calendar.component.html',
  styleUrls: ['./admin-future-dates-calendar.component.css']
})
export class AdminFutureDatesCalendarComponent implements OnInit {
  events: any[];
  reservations: any[];
  dateId: number;
  squares: any[];
  openReservation: boolean;
  openReservationDateId: number;
  gridStyle: any;
  reservationsStyle: any;

  constructor(private eventsService: FutureEventsService, private reservationService: ReservationService) { }

  openReservations(dateId: number) {
    this.dateId = dateId;
    this.openReservation = false;
    this.eventsService.getFutureDateById(dateId).subscribe((data: any) => {
      this.squares = data.squares;
      this.openReservation = true;
    });
    this.reservationService.getReservationsByDate(dateId).subscribe((data: any) => {
      this.reservations = data;
    })
  }

  setGridStyle() {
    this.gridStyle.float = "left";
    this.gridStyle.width = "50%";
  }

  setReservationsStyle() {
    let aspectRatio = window.innerWidth/window.innerHeight;
    this.reservationsStyle.textAlign = "left";
    this.reservationsStyle.fontSize = window.innerHeight * 1/80 + "px";
    if (aspectRatio < 0.75) {
      this.reservationsStyle.fontSize = window.innerWidth * 1/60 + "px";
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setReservationsStyle();
  }

  ngOnInit(): void {
    this.events = [];
    this.reservations = [];
    this.gridStyle = {};
    this.reservationsStyle = {};
    this.openReservation = false;

    this.eventsService.getFutureEvents().subscribe((data: any[]) => {
      this.events = this.eventsService.sortFutureDates(data);
    });

    this.setReservationsStyle();
  }

}
