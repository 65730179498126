import { Component, OnInit, HostListener } from '@angular/core';
import { PageStateService } from '../page-state.service';
import { ScoreService } from '../score.service';

@Component({
  selector: 'app-game-over',
  templateUrl: './game-over.component.html',
  styleUrls: ['./game-over.component.css']
})
export class GameOverComponent implements OnInit {

  gameOverStyle: any;
  scoreStyle: any;
  restartStyle: any;
  containerStyle: any;

  isMobile: boolean;

  constructor(public score: ScoreService, private state: PageStateService) { }

  setGameOverStyle() {
    var textShadow = window.innerWidth * 0.65/512;
    this.containerStyle.left = window.innerWidth * 278/512 + "px";
    this.containerStyle.top = window.innerWidth * 88/512 + "px";
    this.containerStyle.lineHeight = window.innerWidth * 3.5/512 + "px";
    this.gameOverStyle.fontSize = window.innerWidth * 12/512 + "px";
    this.gameOverStyle.lineHeight = window.innerWidth * 10/512 + "px";
    this.scoreStyle.fontSize = window.innerWidth * 7/512 + "px";
    this.restartStyle.fontSize = window.innerWidth * 4.5/512 + "px";

    if (window.innerWidth/window.innerHeight < 0.95) {
      this.containerStyle.left = window.innerWidth * 165/306 + "px";
      this.containerStyle.top = window.innerWidth * 192 /306 - this.state.getTopCutoff() + "px";
      this.containerStyle.lineHeight = window.innerWidth * 3.5/306 + "px";
      this.gameOverStyle.fontSize = window.innerWidth * 12/306 + "px";
      this.gameOverStyle.lineHeight = window.innerWidth * 10/306 + "px";
      this.scoreStyle.fontSize = window.innerWidth * 7/306 + "px";
      this.restartStyle.fontSize = window.innerWidth * 4.5/306 + "px";
      textShadow = window.innerWidth * 0.65/306;

    } else if (this.state.isNarrow()) {
      this.containerStyle.left = window.innerHeight * 278/320 - this.state.getLeftCutoff() + "px";
      this.containerStyle.top = window.innerHeight * 88/320 + "px";
      this.containerStyle.lineHeight = window.innerHeight * 3.5/320 + "px";
      this.gameOverStyle.fontSize = window.innerHeight * 12/320 + "px";
      this.gameOverStyle.lineHeight = window.innerHeight * 10/320 + "px";
      this.scoreStyle.fontSize = window.innerHeight * 7/320 + "px";
      this.restartStyle.fontSize = window.innerHeight * 4.5/320 + "px";
      textShadow = window.innerHeight * 0.65/320;
    }

    this.containerStyle.textShadow = '-' + textShadow + 'px -' + textShadow + 'px 0 #fff, ' + textShadow + 'px -' + textShadow + 'px 0 #fff, -' + textShadow + 'px ' + textShadow + 'px 0 #fff, ' + textShadow + 'px ' + textShadow + 'px 0 #fff';
  } 

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setGameOverStyle();
  }

  ngOnInit(): void {
    this.gameOverStyle = {};
    this.scoreStyle = {};
    this.restartStyle = {};
    this.containerStyle = {};
    this.gameOverStyle.color = "red";

    this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    this.setGameOverStyle();
  }

}
