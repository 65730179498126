import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlService {
  private baseUrl: string;

  constructor() { 
    // this.baseUrl = "https://ec2-34-234-201-186.compute-1.amazonaws.com:8080";
    // this.baseUrl = "http://localhost:8081";
    this.baseUrl = "https://pmx1w9aa46.execute-api.us-east-1.amazonaws.com"
  }

  public getBaseUrl() {
    return this.baseUrl;
  }
}
