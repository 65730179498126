import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-myspace',
  templateUrl: './myspace.component.html',
  styleUrls: ['./myspace.component.css']
})
export class MyspaceComponent implements OnInit {
  profileName: string = "Ol' Liz";

  constructor() { }

  ngOnInit(): void {
    document.getElementById('copyLink').addEventListener('click', function(event) {
      // Prevent default link behavior
      event.preventDefault();
      
      // Get the text to copy
      var textToCopy = this.textContent || this.innerText;
      
      // Create a temporary textarea element
      var textarea = document.createElement('textarea');
      textarea.value = textToCopy;
      
      // Make sure the textarea is not displayed
      textarea.style.position = 'fixed';
      textarea.style.left = '-9999px';
      
      // Append the textarea to the document
      document.body.appendChild(textarea);
      
      // Select and copy the text
      textarea.select();
      document.execCommand('copy');
      
      // Remove the textarea from the document
      document.body.removeChild(textarea);
      
      // Optionally, provide some feedback to the user
      alert('Text copied to clipboard: ' + textToCopy);
  });
  }

}
