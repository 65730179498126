import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class FutureEventsService {
  baseUrl: string;
  eventsEndpoint: string;
  datesEndpoint: string;

  constructor(private http: HttpClient, private urlService: UrlService) {
    this.baseUrl = this.urlService.getBaseUrl();
    this.eventsEndpoint = "/CouchCafe/api/futureevents";
    this.datesEndpoint = "/CouchCafe/api/futuredates";
   }

  public saveFutureEvent(futureEvent: any) {
    return this.http.post(this.baseUrl + this.eventsEndpoint, futureEvent, {});
  }

  public getFutureEvents() {
    return this.http.get(this.baseUrl + this.eventsEndpoint, {});
  }

  public getFutureDateById(dateId: number) {
    return this.http.get(this.baseUrl + this.datesEndpoint + "/" + dateId);
  }

  public formatDateWithoutTimeZone(originalDateString: string): string {
    // Extract the relevant part of the string (excluding the timezone)
    const formattedDateString = originalDateString.substring(0, 19);
  
    // Convert the string to a Date object
    const dateWithoutTimeZone = new Date(formattedDateString);
  
    // Format the date without considering the time zone
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
  
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(dateWithoutTimeZone);
  
    // Format the time without considering the time zone
    const hours = dateWithoutTimeZone.getHours();
    const minutes = dateWithoutTimeZone.getMinutes();
    const amPm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedTime = `${formattedHours}:${minutes.toString().padStart(2, '0')} ${amPm}`;
  
    return `${formattedDate} @ ${formattedTime}`;
  }

  public sortFutureDates(data: any[]) {
    let events: any[] = [];
    const sortedEvents = data.sort((a,b) => {
      let aDate = new Date(a.dates[0].date);
      let bDate = new Date(b.dates[0].date);
      return aDate.getTime() - bDate.getTime();
    });
    
    for (let i = 0; i < sortedEvents.length; i++) {
      const sortedDates = sortedEvents[i].dates.sort((a, b) => {
        let aDate = new Date(a.date);
        let bDate = new Date(b.date);
        return aDate.getTime() - bDate.getTime();
      });
      let event = {
        name: sortedEvents[i].name,
        dates: []
      };
      for (let j = 0; j < data[i].dates.length; j++) {
        event.dates.push({
          dateId: sortedEvents[i].dates[j].id,
          date: this.formatDateWithoutTimeZone(sortedDates[j].date),
          soldOut: sortedEvents[i].dates[j].soldOut
        });
      }
      events.push(event);
    }
    return events;
  }
}
