import { Component, HostListener, Input, OnInit,  } from '@angular/core';
import { GridService } from '../grid.service';

enum ButtonType {
  Empty,
  Table,
  AvailableChair,
  SelectedChair,
  PendingChair,
  ReservedChair,
  Disabled
}

@Component({
  selector: 'app-table-reservation-grid',
  templateUrl: './table-reservation-grid.component.html',
  styleUrls: ['./table-reservation-grid.component.css']
})
export class TableReservationGridComponent implements OnInit {
  @Input() squares: any[];
  grid: ButtonType[][] = [];
  numRows: number;
  numCols: number;
  selectedSeats: any[];
  gridContainerStyle: any;
  gridStyle: any;
  buttonStyle: any;

  constructor(private gridService: GridService) { 
    this.numRows = this.gridService.numRows;
    this.numCols = this.gridService.numCols;
    this.gridContainerStyle = {};
    this.gridStyle = {};
    this.buttonStyle = {};
   }

  initializeGrid() {
    this.getGridContainerStyles();
    this.getGridStyles();
    this.getButtonStyles();
    for (let i = 0; i < this.numRows; i++) {
      this.grid[i] = [];
      for (let j = 0; j < this.numCols; j++) {
        this.grid[i][j] = ButtonType.Empty;
      }
    }
    for (let i = 0; i < this.squares.length; i++) {
      switch (this.squares[i].type) {
        case 'O':
          this.grid[this.squares[i].row][this.squares[i].column] = ButtonType.AvailableChair;
          break;
        case 'T':
          this.grid[this.squares[i].row][this.squares[i].column] = ButtonType.Table;
          break;
        case 'C':
          this.grid[this.squares[i].row][this.squares[i].column] = ButtonType.ReservedChair;
          break;
        case 'P':
          this.grid[this.squares[i].row][this.squares[i].column] = ButtonType.PendingChair;
          break;
      }
    }
  }

  onButtonClick(row: number, col: number) {
    if (this.grid[row][col] === ButtonType.AvailableChair) {
      this.grid[row][col] = ButtonType.SelectedChair;
    } else if (this.grid[row][col] === ButtonType.SelectedChair) {
      this.grid[row][col] = ButtonType.AvailableChair;
    }
  }

  getButtonClass(button: ButtonType): string {
    switch (button) {
      case ButtonType.Empty:
        return 'empty';
      case ButtonType.Table:
        return 'table';
      case ButtonType.AvailableChair:
        return 'chair-available';
      case ButtonType.PendingChair:
        return 'chair-pending';
      case ButtonType.SelectedChair:
        return 'chair-selected';
      case ButtonType.ReservedChair:
        return 'chair-reserved';
      default:
        return '';
    }
  }

  getButtonClickable(i: number, j: number): boolean {
    return (this.grid[i][j] === ButtonType.AvailableChair || this.grid[i][j] === ButtonType.SelectedChair);
  }

  getGridContainerStyles() {
    var aspectRatio = window.innerWidth / window.innerHeight;
    this.gridContainerStyle.width = 5/5 * window.innerWidth * 262/512 + "px";
    this.gridContainerStyle.height = 5/5 * window.innerWidth * 202/512 + "px";
    this.gridContainerStyle.left = 5/5 * window.innerWidth * 3/512 + "px";
    if (aspectRatio < 0.84) { 
      this.gridContainerStyle.width = 7/5 * window.innerWidth * 262/512 + "px";
      this.gridContainerStyle.height = 7/5 * window.innerWidth * 202/512 + "px";
    }
  }

  getGridStyles() {
    var aspectRatio = window.innerWidth / window.innerHeight;
    this.gridStyle.width = 5/5 * window.innerWidth * 180/512 + "px";
    this.gridStyle.left = 5/5 * window.innerWidth * 81/512 + "px";
    this.gridStyle.top = 5/5 * window.innerWidth * 1/512 + "px";
    if (aspectRatio < 0.84) { 
      this.gridStyle.width = 7/5 * window.innerWidth * 180/512 + "px";
      this.gridStyle.left = 7/5 * window.innerWidth * 81/512 + "px";
      this.gridStyle.top = 7/5 * window.innerWidth * 1/512 + "px";
      // this.gridStyle.height = 6/5 * window.innerWidth * 81/512 + "px";
    }
  }

  getButtonStyles() {
    var aspectRatio = window.innerWidth / window.innerHeight;
    this.buttonStyle.width = 5/5 * window.innerWidth * 20/512 + "px";
    this.buttonStyle.height = 5/5 * window.innerWidth * 20/512 + "px";
    if (aspectRatio < 0.84) {
      this.buttonStyle.width = 7/5 * window.innerWidth * 20/512 + "px";
      this.buttonStyle.height = 7/5 * window.innerWidth * 20/512 + "px";
    }
  }

  getButtonImage(row: number, col: number) {
    let buttonStyleImage = this.buttonStyle;
    if (this.grid[row][col] === ButtonType.AvailableChair) {
      if (row > 0 && this.grid[row - 1][col] === ButtonType.Table) {
        buttonStyleImage.backgroundImage = `url(assets/reservations/ChairAvailable_Up.PNG)`;
      } else if (row < this.numRows - 1 && this.grid[row + 1][col] === ButtonType.Table) {
        buttonStyleImage.backgroundImage = `url(assets/reservations/ChairAvailable_Down.PNG)`;
      } else if (col > 0 && this.grid[row][col - 1] === ButtonType.Table) {
        buttonStyleImage.backgroundImage = `url(assets/reservations/ChairAvailable_Left.PNG)`;
      } else if (col < this.numCols - 1 && this.grid[row][col + 1] === ButtonType.Table) {
        buttonStyleImage.backgroundImage = `url(assets/reservations/ChairAvailable_Right.PNG)`;
      } else { 
        buttonStyleImage.backgroundImage = `url(assets/reservations/ChairAvailable_Left.PNG)`;
      }
    } else if (this.grid[row][col] === ButtonType.ReservedChair || this.grid[row][col] === ButtonType.PendingChair) {
      if (row > 0 && this.grid[row - 1][col] === ButtonType.Table) {
        buttonStyleImage.backgroundImage = `url(assets/reservations/ChairReserved_Up.PNG)`;
      } else if (row < this.numRows - 1 && this.grid[row + 1][col] === ButtonType.Table) {
        buttonStyleImage.backgroundImage = `url(assets/reservations/ChairReserved_Down.PNG)`;
      } else if (col > 0 && this.grid[row][col - 1] === ButtonType.Table) {
        buttonStyleImage.backgroundImage = `url(assets/reservations/ChairReserved_Left.PNG)`;
      } else if (col < this.numCols - 1 && this.grid[row][col + 1] === ButtonType.Table) {
        buttonStyleImage.backgroundImage = `url(assets/reservations/ChairReserved_Right.PNG)`;
      } else { 
        buttonStyleImage.backgroundImage = `url(assets/reservations/ChairReserved_Left.PNG)`;
      }
    } else if (this.grid[row][col] === ButtonType.SelectedChair) {
      if (row > 0 && this.grid[row - 1][col] === ButtonType.Table) {
        buttonStyleImage.backgroundImage = `url(assets/reservations/ChairSelected_Up.PNG)`;
      } else if (row < this.numRows - 1 && this.grid[row + 1][col] === ButtonType.Table) {
        buttonStyleImage.backgroundImage = `url(assets/reservations/ChairSelected_Down.PNG)`;
      } else if (col > 0 && this.grid[row][col - 1] === ButtonType.Table) {
        buttonStyleImage.backgroundImage = `url(assets/reservations/ChairSelected_Left.PNG)`;
      } else if (col < this.numCols - 1 && this.grid[row][col + 1] === ButtonType.Table) {
        buttonStyleImage.backgroundImage = `url(assets/reservations/ChairSelected_Right.PNG)`;
      } else { 
        buttonStyleImage.backgroundImage = `url(assets/reservations/ChairSelected_Left.PNG)`;
      }
    } else if (this.grid[row][col] === ButtonType.Table) {
      buttonStyleImage.backgroundImage = `url(assets/reservations/Table.PNG)`;
    } else if (this.grid[row][col] === ButtonType.Empty || this.grid[row][col] === ButtonType.Disabled) {
      buttonStyleImage.backgroundImage = "none";
    }
    
    return buttonStyleImage;
  }

  getSelectedSeats(): any[] {
    let selectedSeats = [];
    for (let i = 0; i < this.numRows; i++) {
      for (let j = 0; j < this.numCols; j++) {
        if (this.grid[i][j] === ButtonType.SelectedChair) {
          for (let k = 0; k < this.squares.length; k++) {
            if (this.squares[k].row === i && this.squares[k].column === j) {
              selectedSeats.push({
                id: this.squares[k].id,
                row: i,
                column: j,
                type: 'P',
                reserved: true
              });
            }
          }
        }
      }
    }
    return selectedSeats;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.initializeGrid();
  }

  ngOnInit(): void {
    this.initializeGrid();
  }

}
