import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  showForm: Boolean = false;

  switchForm() {
    this.showForm = !this.showForm;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
