import { Component, OnInit, HostListener, NgZone, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {PageStateService, PageState } from '../page-state.service'

@Component({
  selector: 'app-menu-buttons',
  templateUrl: './menu-buttons.component.html',
  styleUrls: ['./menu-buttons.component.css']
})
export class MenuButtonsComponent implements OnInit {

  public PageState = PageState;

  futureDatesStyle: any;
  pastMenusStyle: any;
  aboutUsStyle: any;
  myspiceStyle: any;
  backgroundStyle: any;

  backgroundChecked: boolean;

  kitchenFutureMouseOver: string[] = ['assets/kitchen/FutureDates_2.PNG', 'assets/kitchen/FutureDates_3.PNG', 'assets/kitchen/FutureDates_4.PNG'];
  kitchenFutureMouseOverImgs: any[] = [];
  kitchenFutureMouseOut: string[] = ['assets/kitchen/FutureDates.PNG', 'assets/kitchen/FutureDates_6.PNG', 'assets/kitchen/FutureDates_7.PNG'];
  kitchenFutureMouseOutImgs: any[] = [];
  kitchenFutureClicked: string = 'assets/kitchen/FutureDates_clicked.PNG';
  kitchenPastMouseOver: string[] = ["assets/kitchen/PastMenus_2.PNG", "assets/kitchen/PastMenus_3.PNG"];
  kitchenPastMouseOverImgs: any[] = [];
  kitchenPastMouseOut: string[] = ["assets/kitchen/PastMenus.PNG", "assets/kitchen/PastMenus_5.PNG"];
  kitchenPastMouseOutImgs: any[] = [];
  kitchenStoryMouseOver: string[] = ["assets/kitchen/OurStory_2.PNG", "assets/kitchen/OurStory_3.PNG", "assets/kitchen/OurStory_4.PNG"];
  kitchenStoryMouseOverImgs: any[] = [];
  kitchenStoryMouseOut: string[] = ["assets/kitchen/OurStory.PNG", "assets/kitchen/OurStory_6.PNG", "assets/kitchen/OurStory_7.PNG"];
  kitchenStoryMouseOutImgs: any[] = [];
  kitchenMyspiceMouseOver: string[] = ["assets/kitchen/myspice_1.PNG", "assets/kitchen/myspice_2.PNG", "assets/kitchen/myspice_3.PNG", "assets/kitchen/myspice_4.PNG", "assets/kitchen/myspice_5.PNG", "assets/kitchen/myspice_6.PNG"];
  kitchenMyspiceMouseOverImgs: any[] = [];
  kitchenMyspiceMouseOut: string[] = ["assets/kitchen/myspice.PNG", "assets/kitchen/myspice_mo_1.PNG", "assets/kitchen/myspice_mo_2.PNG", "assets/kitchen/myspice_mo_3.PNG", "assets/kitchen/myspice_mo_4.PNG", "assets/kitchen/myspice_mo_5.PNG"];
  kitchenMyspiceMouseOutImgs: any[] = [];
  kitchenMyspiceClicked: string = 'assets/kitchen/myspice_clicked.PNG';

  isFutureMouseOverRunning: boolean = false;
  isFutureMouseOutRunning: boolean = false;
  isFutureHovered: boolean = false;
  isPastMouseOverRunning: boolean = false;
  isPastMouseOutRunning: boolean = false;
  isPastHovered: boolean = false;
  isMyspiceMouseOverRunning: boolean = false;
  isMyspiceMouseOutRunning: boolean = false;
  isMyspiceHovered: boolean = false;

  currentStoryMouseOverImage: number;
  currentStoryMouseOutImage: number;
  currentMenuMouseOverImage: number;
  currentMenuMouseOutImage: number;
  currentDateMouseOverImage: number;
  currentDateMouseOutImage: number;
  currentMyspiceMouseOverImage: number;
  currentMyspiceMouseOutImage: number;

  isMobile: boolean;

  constructor(private ngZone: NgZone, private cdr: ChangeDetectorRef, private state: PageStateService, private router: Router) { }

  setButtonSizes() {
    this.backgroundStyle.height = window.innerHeight + "px";
    this.backgroundStyle.width = window.innerWidth + "px";
    var aspectRatio = window.innerWidth / window.innerHeight;
    var narrow = aspectRatio < 512/320;
    let leftCutoff = this.state.getLeftCutoff();
    if (narrow) {
        this.futureDatesStyle.width = window.innerHeight * 53/320 + "px";
        this.futureDatesStyle.height = window.innerHeight * 96/320 + "px";
        this.futureDatesStyle.left = window.innerHeight * 63/320 - leftCutoff + "px";
        this.futureDatesStyle.top = window.innerHeight * 34/320 + "px";
        this.pastMenusStyle.width = window.innerHeight * 62/320 + "px";
        this.pastMenusStyle.height = window.innerHeight * 102/320 + "px";
        this.pastMenusStyle.left = window.innerHeight * 144/320 - leftCutoff + "px";
        this.pastMenusStyle.top = window.innerHeight * 57/320 + "px";
        this.aboutUsStyle.width = window.innerHeight * 37/320 + "px";
        this.aboutUsStyle.height = window.innerHeight * 89/320 + "px";
        this.aboutUsStyle.left = window.innerHeight * 362/320 - leftCutoff + "px";
        this.aboutUsStyle.top = window.innerHeight * 6/320 + "px";
        this.myspiceStyle.width = window.innerHeight * 96/320 + "px";
        this.myspiceStyle.height = window.innerHeight * 24/320 + "px";
        this.myspiceStyle.left = window.innerHeight * 265/320 - leftCutoff + "px";
        this.myspiceStyle.top = window.innerHeight * 94/320 + "px";
    } else {
        this.futureDatesStyle.width = window.innerWidth * 53/512 + "px";
        this.futureDatesStyle.height = window.innerWidth * 96/512 + "px";
        this.futureDatesStyle.left = window.innerWidth * 63/512 + "px";
        this.futureDatesStyle.top = window.innerWidth * 34/512 + "px";
        this.pastMenusStyle.width = window.innerWidth * 62/512 + "px";
        this.pastMenusStyle.height = window.innerWidth * 102/512 + "px";
        this.pastMenusStyle.left = window.innerWidth * 144/512 + "px";
        this.pastMenusStyle.top = window.innerWidth * 57/512 + "px";
        this.aboutUsStyle.width = window.innerWidth * 37/512 + "px";
        this.aboutUsStyle.height = window.innerWidth * 89/512 + "px";
        this.aboutUsStyle.left = window.innerWidth * 362/512 + "px";
        this.aboutUsStyle.top = window.innerWidth * 6/512 + "px";
        this.myspiceStyle.width = window.innerWidth * 96/512 + "px";
        this.myspiceStyle.height = window.innerWidth * 24/512 + "px";
        this.myspiceStyle.left = window.innerWidth * 265/512 + "px";
        this.myspiceStyle.top = window.innerWidth * 94/512 + "px";
    }
    if (aspectRatio < 1.12) {
      this.futureDatesStyle.width = window.innerWidth * 216/512 + "px";
      this.futureDatesStyle.height = window.innerWidth * 76/512 + "px";
      this.futureDatesStyle.left = window.innerWidth/2 - parseInt(this.futureDatesStyle.width)/2 + "px";
      this.futureDatesStyle.top = window.innerHeight * 225/320 + "px";
      this.kitchenFutureMouseOver = ['assets/kitchen/FutureDatesWide_2.PNG', 'assets/kitchen/FutureDatesWide_3.PNG', 'assets/kitchen/FutureDatesWide_4.PNG'];
      this.kitchenFutureMouseOut = ['assets/kitchen/FutureDatesWide.PNG', 'assets/kitchen/FutureDatesWide_6.PNG', 'assets/kitchen/FutureDatesWide_7.PNG'];
      this.kitchenFutureClicked = 'assets/kitchen/FutureDatesWide_5.PNG';
      this.futureDatesStyle.backgroundImage = `url(assets/kitchen/FutureDatesWide.PNG)`;
      for (let i = 0; i < this.kitchenFutureMouseOver.length; i++) {
        this.kitchenFutureMouseOverImgs[i] = new Image();
        this.kitchenFutureMouseOverImgs[i].src = this.kitchenFutureMouseOver[i];
      }
      for (let i = 0; i < this.kitchenFutureMouseOut.length; i++) {
          this.kitchenFutureMouseOutImgs[i] = new Image();
          this.kitchenFutureMouseOutImgs[i].src = this.kitchenFutureMouseOut[i];
      }
    } else {
      this.kitchenFutureMouseOver = ['assets/kitchen/FutureDates_2.PNG', 'assets/kitchen/FutureDates_3.PNG', 'assets/kitchen/FutureDates_4.PNG'];
      this.kitchenFutureMouseOut = ['assets/kitchen/FutureDates.PNG', 'assets/kitchen/FutureDates_6.PNG', 'assets/kitchen/FutureDates_7.PNG'];
      this.kitchenFutureClicked = 'assets/kitchen/FutureDates_clicked.PNG';
      this.futureDatesStyle.backgroundImage = `url(assets/kitchen/FutureDates.PNG)`;
      for (let i = 0; i < this.kitchenFutureMouseOver.length; i++) {
        this.kitchenFutureMouseOverImgs[i] = new Image();
        this.kitchenFutureMouseOverImgs[i].src = this.kitchenFutureMouseOver[i];
      }
      for (let i = 0; i < this.kitchenFutureMouseOut.length; i++) {
          this.kitchenFutureMouseOutImgs[i] = new Image();
          this.kitchenFutureMouseOutImgs[i].src = this.kitchenFutureMouseOut[i];
      }
    }
    if (aspectRatio < 0.95) {
      this.futureDatesStyle.top = window.innerWidth * 355/306*(1 + 0.2*(306*window.innerHeight/window.innerWidth - 322)/340) - this.state.getCounterTopCutoff() + "px";
      this.pastMenusStyle.width = window.innerWidth * 62/306 + "px";
      this.pastMenusStyle.height = window.innerWidth * 102/306 + "px";
      this.pastMenusStyle.left = window.innerWidth * 28/306 + "px";
      this.pastMenusStyle.top = window.innerWidth * 161/306 - this.state.getTopCutoff() + "px";
      this.aboutUsStyle.width = window.innerWidth * 37/306 + "px";
      this.aboutUsStyle.height = window.innerWidth * 89/306 + "px";
      this.aboutUsStyle.left = window.innerWidth * 246/306 + "px";
      this.aboutUsStyle.top = window.innerWidth * 110/306 - this.state.getTopCutoff() + "px";
      this.myspiceStyle.width = window.innerWidth * 96/306 + "px";
      this.myspiceStyle.height = window.innerWidth * 24/306 + "px";
      this.myspiceStyle.left = window.innerWidth * 149/306 + "px";
      this.myspiceStyle.top = window.innerWidth * 198/306 - this.state.getTopCutoff() + "px";
    }
    if (aspectRatio < 0.65) {
      this.futureDatesStyle.width = window.innerWidth * 216/512 + "px";
      this.futureDatesStyle.height = window.innerWidth * 76/512 + "px";
      this.futureDatesStyle.top = window.innerWidth * 355/306*(1 + 0.085*(306*window.innerHeight/window.innerWidth - 470)/192)*(1 + 0.2*(306*window.innerHeight/window.innerWidth - 322)/340) - this.state.getCounterTopCutoff() + "px";
      this.futureDatesStyle.left = window.innerWidth/2 - parseInt(this.futureDatesStyle.width)/2 + "px";
    }
    if (aspectRatio < 306/662) {
      this.futureDatesStyle.width = window.innerHeight * 216/512*1.5*306/662 + "px";
      this.futureDatesStyle.height = window.innerHeight * 76/512*1.5*306/662  + "px";
      this.futureDatesStyle.top = window.innerWidth * 355/306*(1 + 0.095*(306*window.innerHeight/window.innerWidth - 470)/192)*(1 + 0.2*(306*window.innerHeight/window.innerWidth - 322)/340) - this.state.getCounterTopCutoff() + "px";
      this.futureDatesStyle.left = window.innerWidth/2 - parseInt(this.futureDatesStyle.width)/2 + "px";
      this.pastMenusStyle.width = window.innerHeight * 62/662 + "px";
      this.pastMenusStyle.height = window.innerHeight * 102/662 + "px";
      this.pastMenusStyle.left = window.innerHeight * 28/662 + "px";
      this.pastMenusStyle.top = window.innerHeight * 161/662 + "px";
      this.aboutUsStyle.width = window.innerHeight * 37/662 + "px";
      this.aboutUsStyle.height = window.innerHeight * 89/662 + "px";
      this.aboutUsStyle.left = window.innerHeight * 246/662 + "px";
      this.aboutUsStyle.top = window.innerHeight * 110/662 + "px";
      this.myspiceStyle.width = window.innerHeight * 96/662 + "px";
      this.myspiceStyle.height = window.innerHeight * 24/662 + "px";
      this.myspiceStyle.left = window.innerHeight * 149/662 + "px";
      this.myspiceStyle.top = window.innerHeight * 198/662 + "px";
    }
    this.futureDatesStyle.backgroundImage = `url(${this.kitchenFutureMouseOutImgs[0].src})`;
    this.cdr.detectChanges();
  }

  futureDatesMouseEnterWrapper() {
    this.isFutureHovered = true;
    this.isFutureMouseOverRunning = true;
    this.futureDatesMouseEnter();
  }

  futureDatesMouseEnter() {
    this.isFutureHovered = true;
    this.isFutureMouseOutRunning = false;
    if (this.isFutureMouseOverRunning) {
      if(this.currentDateMouseOverImage >= this.kitchenFutureMouseOver.length) {
        this.isFutureMouseOverRunning = false;
      } else {
          this.futureDatesStyle.backgroundImage = `url(${this.kitchenFutureMouseOverImgs[this.currentDateMouseOverImage].src})`;
          this.currentDateMouseOverImage++;
          setTimeout(() => {
              this.ngZone.runOutsideAngular(() => requestAnimationFrame(() => this.futureDatesMouseEnter()));
          }, 50);
      }
      this.cdr.detectChanges();
    }
  }

  futureDatesMouseOutWrapper() {
    this.isFutureHovered = false;
    this.isFutureMouseOutRunning = true;
    this.futureDatesMouseOut();
  }

  futureDatesMouseOut() {
    this.isFutureHovered = false;
    this.isFutureMouseOverRunning = false;
    if (this.isFutureMouseOutRunning) {
      this.currentDateMouseOverImage = 0;
      if(this.currentDateMouseOutImage >= this.kitchenFutureMouseOut.length - 1) {
          this.currentDateMouseOutImage = 0;
          this.futureDatesStyle.backgroundImage = `url(${this.kitchenFutureMouseOutImgs[this.currentDateMouseOutImage].src})`;
          this.isFutureMouseOutRunning = false;
      } else {
          this.currentDateMouseOutImage++;
          this.futureDatesStyle.backgroundImage = `url(${this.kitchenFutureMouseOutImgs[this.currentDateMouseOutImage].src})`;
          setTimeout(() => {
              this.ngZone.runOutsideAngular(() => requestAnimationFrame(() => this.futureDatesMouseOut()));
          }, 100);
          
      }
      this.cdr.detectChanges();
    }
  }
  
  futureDatesMouseDown() {
    if (window.innerWidth / window.innerHeight < 1.12) {
      // this.futureDatesStyle.backgroundImage = 
    }
    this.futureDatesStyle.backgroundImage = `url(${this.kitchenFutureClicked})`;
    this.cdr.detectChanges();
  }

  futureDatesClick() {
      this.state.currentState = PageState.FUTURE;
      this.cdr.detectChanges();
  }

  pastMenusMouseEnterWrapper() {
    this.isPastHovered = true;
    this.isPastMouseOverRunning = true;
    this.pastMenusMouseEnter();
  }

  pastMenusMouseEnter() {
    this.isPastHovered = true;
    this.isPastMouseOutRunning = false;
    if (this.isPastMouseOverRunning) {
      if(this.currentMenuMouseOverImage >= this.kitchenPastMouseOver.length) {
        this.isPastMouseOverRunning = false;
      } else {
          this.pastMenusStyle.backgroundImage = `url(${this.kitchenPastMouseOverImgs[this.currentMenuMouseOverImage].src})`;
          this.currentMenuMouseOverImage++;
          setTimeout(() => {
              this.ngZone.runOutsideAngular(() => requestAnimationFrame(() => this.pastMenusMouseEnter()));
          }, 50);
      }
      this.cdr.detectChanges();
    }
  }

  pastMenusMouseOutWrapper() {
    this.isPastHovered = false;
    this.isPastMouseOutRunning = true;
    this.pastMenusMouseOut();
  }

  pastMenusMouseOut() {
    this.isPastHovered = false;
    this.isPastMouseOverRunning = false;
    if (this.isPastMouseOutRunning) {
      this.currentMenuMouseOverImage = 0;
      if(this.currentMenuMouseOutImage >= this.kitchenPastMouseOut.length - 1) {
          this.currentMenuMouseOutImage = 0;
          this.pastMenusStyle.backgroundImage = `url(${this.kitchenPastMouseOutImgs[this.currentMenuMouseOutImage].src})`;
          this.isPastMouseOutRunning = false;
      } else {
          this.pastMenusStyle.backgroundImage = `url(${this.kitchenPastMouseOutImgs[this.currentMenuMouseOutImage].src})`;
          this.currentMenuMouseOutImage++;
          setTimeout(() => {
              this.ngZone.runOutsideAngular(() => requestAnimationFrame(() => this.pastMenusMouseOut()));
          }, 100);
      }
      this.cdr.detectChanges();
    }
  }

  pastMenusMouseDown() {
    this.pastMenusStyle.backgroundImage = `url(assets/kitchen/PastMenus_clicked.PNG)`;
    this.cdr.detectChanges();
  }

  pastMenusClick() {
    this.state.currentState = PageState.PAST;
    this.cdr.detectChanges();
  }

  aboutUsMouseEnter() {
    if(this.currentStoryMouseOverImage >= this.kitchenStoryMouseOver.length) {
        // break
    } else {
        this.aboutUsStyle.backgroundImage = `url(${this.kitchenStoryMouseOverImgs[this.currentStoryMouseOverImage].src})`;
        this.currentStoryMouseOverImage++;
        setTimeout(() => {
            this.ngZone.runOutsideAngular(() => requestAnimationFrame(() => this.aboutUsMouseEnter()));
        }, 50);
    }
    this.cdr.detectChanges();
  }

  aboutUsMouseOut() {
    this.currentStoryMouseOverImage = 0;
    if(this.currentStoryMouseOutImage >= this.kitchenStoryMouseOut.length-1) {
        this.currentStoryMouseOutImage = 0;
        this.aboutUsStyle.backgroundImage = `url(${this.kitchenStoryMouseOutImgs[this.currentStoryMouseOutImage].src})`;
    } else {
        this.currentStoryMouseOutImage++;
        this.aboutUsStyle.backgroundImage = `url(${this.kitchenStoryMouseOutImgs[this.currentStoryMouseOutImage].src})`;
        setTimeout(() => {
            this.ngZone.runOutsideAngular(() => requestAnimationFrame(() => this.aboutUsMouseOut()));
        }, 50);
    }
    this.cdr.detectChanges();
  }

  aboutUsMouseDown() {
    this.aboutUsStyle.backgroundImage = `url(assets/kitchen/OurStory_clicked.PNG)`;
    this.cdr.detectChanges();
  }

  aboutUsClick() {
    this.state.setCurrentState(PageState.ABOUT);
  }

  myspiceMouseOverWrapper() {
    this.isMyspiceHovered = true;
    this.isMyspiceMouseOverRunning = true;
    this.myspiceMouseOver();
  }

  myspiceMouseOver() {
    this.isMyspiceMouseOutRunning = false;
    if (this.isMyspiceMouseOverRunning) {
      // this.currentMyspiceMouseOutImage = 0;
      if(this.currentMyspiceMouseOverImage >= this.kitchenMyspiceMouseOver.length) {
        this.isMyspiceMouseOverRunning = false;
      } else {
          this.myspiceStyle.backgroundImage = `url(${this.kitchenMyspiceMouseOverImgs[this.currentMyspiceMouseOverImage].src})`;
          this.currentMyspiceMouseOverImage++;
          setTimeout(() => {
              this.ngZone.runOutsideAngular(() => requestAnimationFrame(() => this.myspiceMouseOver()));
          }, 60);
      }
      this.cdr.detectChanges();
    } 
  }

  myspiceMouseOutWrapper() {
    this.isMyspiceHovered = false;
    this.isMyspiceMouseOutRunning = true;
    this.myspiceMouseOut();
  }

  myspiceMouseOut() {
    this.isMyspiceMouseOverRunning = false;
    if (this.isMyspiceMouseOutRunning) {
      this.currentMyspiceMouseOverImage = 0;
      if(this.currentMyspiceMouseOutImage >= this.kitchenMyspiceMouseOut.length-1) {
          this.currentMyspiceMouseOutImage = 0;
          this.myspiceStyle.backgroundImage = `url(${this.kitchenMyspiceMouseOutImgs[this.currentMyspiceMouseOutImage].src})`;
          this.isMyspiceMouseOutRunning = false;
      } else {
          this.currentMyspiceMouseOutImage++;
          this.myspiceStyle.backgroundImage = `url(${this.kitchenMyspiceMouseOutImgs[this.currentMyspiceMouseOutImage].src})`;
          setTimeout(() => {
              this.ngZone.runOutsideAngular(() => requestAnimationFrame(() => this.myspiceMouseOut()));
          }, 100);
      }
      this.cdr.detectChanges();
    }
  }

  myspiceMouseDown() {
    this.myspiceStyle.backgroundImage = `url(assets/kitchen/myspice_clicked.PNG)`;
    this.cdr.detectChanges();
  }

  myspiceClick() {
    // this.state.setCurrentState(PageState.ABOUT);
    if (this.isMobile) {
      this.myspiceMouseOverWrapper();
      setTimeout(() => {
        this.router.navigate(['myspice']);
        this.cdr.detectChanges();
      }, 500);
    } else {
      this.router.navigate(['myspice']);
    }
  }

  backgroundMouseEnter() {
    if (!this.aboutUsStyle.backgroundImage.includes("OurStory.PNG")) {
      this.aboutUsMouseOut();
    }
    if (!this.futureDatesStyle.backgroundImage.includes("FutureDates.PNG") && !this.futureDatesStyle.backgroundImage.includes("FutureDatesWide.PNG")) {
      this.isFutureMouseOutRunning = true;
      this.futureDatesMouseOut();
    }
    if (!this.pastMenusStyle.backgroundImage.includes("PastMenus.PNG")) {
      this.isPastMouseOutRunning = true;
      this.pastMenusMouseOut();
    }
    if (!this.myspiceStyle.backgroundImage.includes("myspice.PNG")) {
      this.isMyspiceMouseOutRunning = true;
      this.myspiceMouseOut();
    }
  }

  checkButtons() {
    if (!this.aboutUsStyle.backgroundImage.includes("OurStory.PNG")) {
      this.aboutUsMouseOut();
    }
    if (!this.isFutureHovered && !this.futureDatesStyle.backgroundImage.includes("FutureDates.PNG") && !this.futureDatesStyle.backgroundImage.includes("FutureDatesWide.PNG")) {
      this.isFutureMouseOutRunning = true;
      this.futureDatesMouseOut();
    }
    if (!this.isPastHovered && !this.pastMenusStyle.backgroundImage.includes("PastMenus.PNG")) {
      this.isPastMouseOutRunning = true;
      this.pastMenusMouseOut();
    }
    if (!this.isMyspiceHovered && !this.myspiceStyle.backgroundImage.includes("myspice.PNG")) {
      this.isMyspiceMouseOutRunning = true;
      this.myspiceMouseOut();
    }
    setTimeout(() => {
      this.ngZone.runOutsideAngular(() => requestAnimationFrame(() => this.checkButtons()));
  }, 250);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setButtonSizes();
  }

  ngOnInit(): void {
    this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || (navigator.userAgent.includes("Macintosh") && navigator.maxTouchPoints > 1);
    this.state.currentState = PageState.HOME;
    this.futureDatesStyle = {};
    this.pastMenusStyle = {};
    this.aboutUsStyle = {};
    this.myspiceStyle = {};
    this.backgroundStyle = {};
    this.backgroundStyle.left = "0px";
    this.backgroundChecked = true;
    this.setButtonSizes();

    for (let i = 0; i < this.kitchenFutureMouseOver.length; i++) {
        this.kitchenFutureMouseOverImgs[i] = new Image();
        this.kitchenFutureMouseOverImgs[i].src = this.kitchenFutureMouseOver[i];
    }
    for (let i = 0; i < this.kitchenFutureMouseOut.length; i++) {
        this.kitchenFutureMouseOutImgs[i] = new Image();
        this.kitchenFutureMouseOutImgs[i].src = this.kitchenFutureMouseOut[i];
    }
    for (let i = 0; i < this.kitchenPastMouseOver.length; i++) {
        this.kitchenPastMouseOverImgs[i] = new Image();
        this.kitchenPastMouseOverImgs[i].src = this.kitchenPastMouseOver[i];
    }
    for (let i = 0; i < this.kitchenPastMouseOut.length; i++) {
        this.kitchenPastMouseOutImgs[i] = new Image();
        this.kitchenPastMouseOutImgs[i].src = this.kitchenPastMouseOut[i];
    }
    for (let i = 0; i < this.kitchenStoryMouseOver.length; i++) {
        this.kitchenStoryMouseOverImgs[i] = new Image();
        this.kitchenStoryMouseOverImgs[i].src = this.kitchenStoryMouseOver[i];
    }
    for (let i = 0; i < this.kitchenStoryMouseOut.length; i++) {
        this.kitchenStoryMouseOutImgs[i] = new Image();
        this.kitchenStoryMouseOutImgs[i].src = this.kitchenStoryMouseOut[i];
    }
    for (let i = 0; i < this.kitchenMyspiceMouseOver.length; i++) {
      this.kitchenMyspiceMouseOverImgs[i] = new Image();
      this.kitchenMyspiceMouseOverImgs[i].src = this.kitchenMyspiceMouseOver[i];
    }
    for (let i = 0; i < this.kitchenMyspiceMouseOut.length; i++) {
        this.kitchenMyspiceMouseOutImgs[i] = new Image();
        this.kitchenMyspiceMouseOutImgs[i].src = this.kitchenMyspiceMouseOut[i];
    }

    this.pastMenusStyle.backgroundImage = `url(assets/kitchen/PastMenus.PNG)`;
    this.aboutUsStyle.backgroundImage = `url(assets/kitchen/OurStory.PNG)`;
    this.myspiceStyle.backgroundImage = `url(assets/kitchen/myspice.PNG)`;
    //this.futureDatesStyle.backgroundImage = `url(assets/kitchen/FutureDates.PNG)`;

    this.currentStoryMouseOverImage = 0;
    this.currentStoryMouseOutImage = 0;
    this.currentMenuMouseOverImage = 0;
    this.currentMenuMouseOutImage = 0;
    this.currentDateMouseOverImage = 0;
    this.currentDateMouseOutImage = 0;
    this.currentMyspiceMouseOutImage = 0;
    this.currentMyspiceMouseOverImage = 0;

    this.checkButtons();
  }

}
