import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PasswordService } from '../password.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  password = '';
  hashedPassword = '$2a$10$rKTJSpvUTnbmo7GE4HRdQu.AAbn6xk9KtTLElt8ElLdI2wJRNGWF.';

  constructor(private passwordService: PasswordService, private router: Router) { }

  async login() {
    const isPasswordCorrect = await this.passwordService.comparePasswords(this.password,this.hashedPassword);

    if (isPasswordCorrect) {
      this.passwordService.isAuthenticated = true;
      this.router.navigate(['/admin']);
    }
  }

  ngOnInit(): void {
    
  }

}
