import { Component, OnInit, HostListener, ChangeDetectorRef, ViewChild, ElementRef, NgZone } from '@angular/core';
import { PageStateService, PageState } from '../page-state.service';
import { Router } from '@angular/router';
import { FutureEventsService } from '../future-events.service';

const monthNames = [
  "January", "February", "March", "April",
  "May", "June", "July", "August",
  "September", "October", "November", "December"
];

@Component({
  selector: 'app-future-dates-calendar',
  templateUrl: './future-dates-calendar.component.html',
  styleUrls: ['./future-dates-calendar.component.css']
})
export class FutureDatesCalendarComponent implements OnInit {

  public PageState = PageState;

  @ViewChild('futureDates') calendar: ElementRef;
  @ViewChild('previousMonthButton') previousButton: ElementRef;
  @ViewChild('nextMonthButton') nextButton: ElementRef;

  calendarStyle: any;
  calendarOpenedStyle: any;
  reservationButtonStyle: any;
  buttonStyle: any;
  previousButtonStyle: any;
  nextButtonStyle: any;
  events: any[];
  currentMonthEvents: any[];

  currentDate: Date;
  currentMonth: number;
  currentMonthName: string;
  selectedMonth: number;
  selectedMonthName: string;
  selectedYear: number;
  currentYear: number;

  pageOpened: boolean;
  textEnabled: boolean;

  constructor(private cdr: ChangeDetectorRef, private state: PageStateService, private ngZone: NgZone, private eventsService: FutureEventsService, private router: Router) { }

  setCalendarSize() {
    let aspectRatio = window.innerWidth/window.innerHeight;
    this.calendarStyle.width = window.innerWidth * 28/512 + "px";
    this.calendarStyle.height = window.innerWidth * 27/512 + "px";
    this.calendarStyle.left = window.innerWidth * 78/512 + "px";
    this.calendarStyle.top = window.innerWidth * 64/512 + "px";
    this.calendarStyle.paddingTop = window.innerWidth * 12/512 + "px";
    this.calendarStyle.paddingLeft = window.innerWidth * 2.4/512 + "px";
    this.calendarStyle.fontSize = window.innerWidth * 1.44/512 + "px";
    this.calendarStyle.textAlign = "left";
    this.calendarOpenedStyle.width = window.innerWidth * 151/512 + "px";
    this.calendarOpenedStyle.height = window.innerWidth * 131/512 + "px";
    this.calendarOpenedStyle.left = window.innerWidth * 90/512 + "px";
    this.calendarOpenedStyle.top = window.innerWidth * 32/512 + "px";
    this.calendarOpenedStyle.paddingRight = "0px";
    this.calendarOpenedStyle.textAlign = "left";
    if (aspectRatio < 0.65) {
      console.log("0.65")
      this.calendarStyle.top = window.innerWidth * 363/306*(1 + 0.095*(306*window.innerHeight/window.innerWidth - 470)/192)*(1 + 0.2*(306*window.innerHeight/window.innerWidth - 322)/340) - this.state.getCounterTopCutoff() + "px";
      this.calendarStyle.left = window.innerWidth/2 - (window.innerWidth * 216/512*(1 + 0.5*(306*window.innerHeight/window.innerWidth - 322)/340))/2 + window.innerWidth*10/306 + "px";
      this.calendarStyle.height = window.innerWidth * 27/306*(1 + 0.5*(306*window.innerHeight/window.innerWidth - 322)/340) + "px";
      this.calendarStyle.width = window.innerWidth * 39/306*(1 + 0.5*(306*window.innerHeight/window.innerWidth - 322)/340) + "px";
      this.calendarStyle.paddingTop = window.innerWidth * 16.8/306*(1 + 0.5*(306*window.innerHeight/window.innerWidth - 322)/340) + "px";
      this.calendarStyle.paddingLeft = window.innerWidth * 3.36/306*(1 + 0.5*(306*window.innerHeight/window.innerWidth - 322)/340) + "px";
      this.calendarStyle.fontSize = window.innerWidth * 1.44/306 + "px";
      this.calendarOpenedStyle.width = window.innerWidth * 151/306 + "px";
      this.calendarOpenedStyle.height = window.innerWidth * 131/306 + "px";
      this.calendarOpenedStyle.left = window.innerHeight * 28/320 + "px";
      this.calendarOpenedStyle.top = window.innerWidth * 135/306 - this.state.getTopCutoff() + "px";
    } else if (aspectRatio < 0.95) {
      console.log("0.95")
      this.calendarStyle.width = window.innerWidth * 30/306 + "px";
      this.calendarStyle.height = window.innerWidth * 27/306 + "px";
      this.calendarStyle.left = window.innerWidth/2 - window.innerHeight * 146/512/2 + "px";
      this.calendarStyle.top = window.innerWidth * 357/306*(1 + 0.2*(306*window.innerHeight/window.innerWidth - 322)/340) - this.state.getCounterTopCutoff() + "px";
      this.calendarStyle.paddingTop = window.innerWidth * 12/306 + "px";
      this.calendarStyle.fontSize = window.innerWidth * 1.44/306 + "px";
      this.calendarOpenedStyle.width = window.innerWidth * 151/306 + "px";
      this.calendarOpenedStyle.height = window.innerWidth * 131/306 + "px";
      this.calendarOpenedStyle.left = window.innerHeight * 28/320 + "px";
      this.calendarOpenedStyle.top = window.innerWidth * 135/306 - this.state.getTopCutoff() + "px";
    } else if (aspectRatio < 1.12) {
      this.calendarStyle.width = window.innerHeight * 39/320 + "px";
      this.calendarStyle.height = window.innerHeight * 27/320 + "px";
      this.calendarStyle.left = window.innerWidth/2 - window.innerHeight * 216/512/2 + "px";
      this.calendarStyle.top = window.innerHeight * 230/320 + "px";
      this.calendarStyle.paddingTop = window.innerHeight * 12/320 + "px";
      this.calendarStyle.fontSize = window.innerHeight * 1.44/320 + "px";
      this.calendarOpenedStyle.width = window.innerHeight * 151/320 + "px";
      this.calendarOpenedStyle.height = window.innerHeight * 131/320 + "px";
      this.calendarOpenedStyle.left = window.innerHeight * 28/320 + "px";
      this.calendarOpenedStyle.top = window.innerHeight * 32/320 + "px";
    } else if (aspectRatio < 512/320) {
      this.calendarStyle.width = window.innerHeight * 28/320 + "px";
      this.calendarStyle.height = window.innerHeight * 27/320 + "px";
      this.calendarStyle.left = window.innerHeight * 78/320 - this.state.getLeftCutoff() + "px";
      this.calendarStyle.top = window.innerHeight * 64/320 + "px";
      this.calendarStyle.paddingTop = window.innerHeight * 12/320 + "px";
      this.calendarStyle.fontSize = window.innerHeight * 1.44/320 + "px";
      this.calendarOpenedStyle.width = window.innerHeight * 151/320 + "px";
      this.calendarOpenedStyle.height = window.innerHeight * 131/320 + "px";
      this.calendarOpenedStyle.left = window.innerHeight * 90/320 - this.state.getLeftCutoff() + "px";
      this.calendarOpenedStyle.top = window.innerHeight * 32/320 + "px";
    }

    let calendarHeight = parseFloat(this.calendarOpenedStyle.height);
    this.calendarOpenedStyle.paddingTop = calendarHeight * 30/131 + "px";
    this.calendarOpenedStyle.paddingLeft = calendarHeight * 10/131 + "px";
    this.calendarOpenedStyle.fontSize = calendarHeight * 2.7/131 + "px";
    this.calendarOpenedStyle.lineHeight = calendarHeight * 7.2/131 + "px";
    this.cdr.detectChanges();
  }

  setButtonStyle() {
    let aspectRatio = window.innerWidth/window.innerHeight;
    this.buttonStyle.fontSize = window.innerWidth * 3/512 + "px";
    // this.buttonStyle.height = window.innerWidth * 7/512 + "px";
    this.buttonStyle.padding = window.innerWidth * 1.5/512 + "px";
    this.buttonStyle.borderWidth = window.innerWidth * 1/512 + "px";
    if (aspectRatio < 0.95) {
      this.buttonStyle.fontSize = window.innerWidth * 3/306 + "px";
      // this.buttonStyle.height = window.innerWidth * 7/306 + "px";
      this.buttonStyle.padding = window.innerWidth * 1.5/306 + "px";
      this.buttonStyle.borderWidth = window.innerWidth * 1/306 + "px";
    } else if (aspectRatio < 512/320) {
      this.buttonStyle.fontSize = window.innerHeight * 3/320 + "px";
      // this.buttonStyle.height = window.innerHeight * 7/320 + "px";
      this.buttonStyle.padding = window.innerHeight * 1.5/320 + "px";
      this.buttonStyle.borderWidth = window.innerHeight * 1/320 + "px";
    }

    this.previousButtonStyle = { ...this.buttonStyle };
    this.nextButtonStyle = { ...this.buttonStyle };

    this.previousButtonStyle.left = parseFloat(this.calendarOpenedStyle.left) + 1/3*parseFloat(this.calendarOpenedStyle.paddingLeft) + "px";
    this.nextButtonStyle.left = parseFloat(this.calendarOpenedStyle.left) + parseFloat(this.calendarOpenedStyle.width) - parseFloat(this.calendarOpenedStyle.paddingLeft) + "px";
    this.previousButtonStyle.top = parseFloat(this.calendarOpenedStyle.top) + parseFloat(this.calendarOpenedStyle.height) + 5/8*parseFloat(this.calendarOpenedStyle.paddingTop) + "px";
    this.nextButtonStyle.top = parseFloat(this.calendarOpenedStyle.top) + parseFloat(this.calendarOpenedStyle.height) + 5/8*parseFloat(this.calendarOpenedStyle.paddingTop) + "px";
  }

  openPage() {
    if (parseInt(this.calendarStyle.height, 10)/parseInt(this.calendarOpenedStyle.height) < 0.95) {
      this.calendarStyle.width = parseInt(this.calendarStyle.width, 10) + 1/3*(parseInt(this.calendarOpenedStyle.width) - parseInt(this.calendarStyle.width, 10)) + "px";
      this.calendarStyle.height = parseInt(this.calendarStyle.height, 10) + 1/3*(parseInt(this.calendarOpenedStyle.height) - parseInt(this.calendarStyle.height, 10)) + "px";
      this.calendarStyle.left = parseInt(this.calendarStyle.left, 10) + 1/3*(parseInt(this.calendarOpenedStyle.left) - parseInt(this.calendarStyle.left, 10)) + "px";
      this.calendarStyle.top = parseInt(this.calendarStyle.top, 10) + 1/3*(parseInt(this.calendarOpenedStyle.top) - parseInt(this.calendarStyle.top, 10)) + "px";
      this.calendarStyle.paddingTop = parseInt(this.calendarStyle.paddingTop, 10) + 1/3*(parseInt(this.calendarOpenedStyle.paddingTop) - parseInt(this.calendarStyle.paddingTop, 10)) + "px";
      this.calendarStyle.fontSize = parseInt(this.calendarStyle.fontSize, 10) + 1/3*(parseInt(this.calendarOpenedStyle.fontSize) - parseInt(this.calendarStyle.fontSize, 10)) + "px";
      setTimeout(() => {
        this.ngZone.runOutsideAngular(() => requestAnimationFrame(() => this.openPage()));
      }, 50);
    } else {
      this.pageOpened = true;
      this.textEnabled = true;
      this.setCalendarSize();
      this.setButtonStyle();
    }
    this.cdr.detectChanges();
  }

  closePage() {
    this.textEnabled = false;
    if (parseInt(this.calendarOpenedStyle.height, 10)/parseInt(this.calendarStyle.height, 10) > 1.05) {
      this.calendarOpenedStyle.width = parseInt(this.calendarOpenedStyle.width, 10) + 1/3*(parseInt(this.calendarStyle.width) - parseInt(this.calendarOpenedStyle.width, 10)) + "px";
      this.calendarOpenedStyle.height = parseInt(this.calendarOpenedStyle.height, 10) + 1/3*(parseInt(this.calendarStyle.height) - parseInt(this.calendarOpenedStyle.height, 10)) + "px";
      this.calendarOpenedStyle.left = parseInt(this.calendarOpenedStyle.left, 10) + 1/3*(parseInt(this.calendarStyle.left) - parseInt(this.calendarOpenedStyle.left, 10)) + "px";
      this.calendarOpenedStyle.top = parseInt(this.calendarOpenedStyle.top, 10) + 1/3*(parseInt(this.calendarStyle.top) - parseInt(this.calendarOpenedStyle.top, 10)) + "px";
      this.calendarOpenedStyle.paddingTop = parseInt(this.calendarOpenedStyle.paddingTop, 10) + 1/3*(parseInt(this.calendarStyle.paddingTop) - parseInt(this.calendarOpenedStyle.paddingTop, 10)) + "px";
      this.calendarOpenedStyle.fontSize = parseInt(this.calendarOpenedStyle.fontSize, 10) + 1/3*(parseInt(this.calendarStyle.fontSize) - parseInt(this.calendarOpenedStyle.fontSize, 10)) + "px";
      
      setTimeout(() => {
        this.ngZone.runOutsideAngular(() => requestAnimationFrame(() => this.closePage()));
      }, 20);
    } else {
      this.pageOpened = false;
      this.state.setCurrentState(PageState.HOME);
    }
    this.cdr.detectChanges();
  }

  openReservation(dateId: number) {
    this.ngZone.run(() => {
      this.router.navigate(['/reservations', dateId]);
    });
  }

  getCurrentMonthDates() {
    this.currentMonthEvents = [];
    
    for (let i = 0; i < this.events.length; i++) {
      for (let j = 0; j < this.events[i].dates.length; j++) {
        if (this.events[i].dates[j].date.includes(monthNames[this.selectedMonth]) && this.events[i].dates[j].date.includes(this.selectedYear)) {
          this.currentMonthEvents.push(this.events[i]);
          break;
        }
      }
    }
  }

  nextMonth() {
    if (this.selectedMonth < 11) {
      this.selectedMonth++;
    } else {
      this.selectedMonth = 0;
      this.selectedYear++;
    }
    this.selectedMonthName = monthNames[this.selectedMonth];
    this.getCurrentMonthDates();
  }

  previousMonth() {
    if (this.selectedMonth > 0) {
      this.selectedMonth--;
    } else {
      this.selectedMonth = 11;
      this.selectedYear--;
    }
    this.selectedMonthName = monthNames[this.selectedMonth];
    this.getCurrentMonthDates();
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (this.state.currentState === PageState.FUTURE 
      && !this.calendar.nativeElement.contains(event.target) 
      && !this.previousButton.nativeElement.contains(event.target)
      && !this.nextButton.nativeElement.contains(event.target)
      && this.pageOpened) {
      this.closePage();
      this.cdr.detectChanges();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setCalendarSize();
    this.setButtonStyle();
  }

  ngOnInit(): void {
    this.calendarStyle = {};
    this.calendarOpenedStyle = {};
    this.buttonStyle = {};
    this.previousButtonStyle = {};
    this.nextButtonStyle = {};
    this.events = [];
    this.calendarStyle.backgroundImage = `url("assets/Calendar.PNG")`;
    this.calendarOpenedStyle.backgroundImage = `url("assets/Calendar.PNG")`;

    this.currentDate = new Date();
    this.currentMonth = this.currentDate.getMonth();
    this.selectedMonth = this.currentMonth;
    this.selectedMonthName = monthNames[this.selectedMonth];
    this.currentMonthName = monthNames[this.currentDate.getMonth()];
    this.currentYear = this.currentDate.getFullYear();
    this.selectedYear = this.currentYear;

    // this.http.get('assets/futureDates.txt', { responseType: 'text' }).subscribe(data => {
    //   this.futureDatesText = data;
    // });

    this.eventsService.getFutureEvents().subscribe((data: any[]) => {
      this.events = this.eventsService.sortFutureDates(data);
      this.getCurrentMonthDates();
    });

    this.pageOpened = false;
    this.textEnabled = false;

    this.setCalendarSize();
    this.setButtonStyle();
    setTimeout(() => {
      this.setCalendarSize();
      this.setButtonStyle();
    }, 500);
    this.openPage();
  }

}
