import { Component, Input, OnInit } from '@angular/core';
import { GridService } from '../grid.service';

enum ButtonType {
  Empty,
  Table,
  AvailableChair,
  SelectedChair,
  PendingChair,
  ReservedChair
}

@Component({
  selector: 'app-admin-reservation-grid',
  templateUrl: './admin-reservation-grid.component.html',
  styleUrls: ['./admin-reservation-grid.component.css']
})
export class AdminReservationGridComponent implements OnInit {
  @Input() squares: any[];
  grid: any[][] = [];
  numRows: number;
  numCols: number;
  selectedSeat: any;

  constructor(private gridService: GridService) { 
    this.numRows = this.gridService.numRows;
    this.numCols = this.gridService.numCols;
   }

  initializeGrid() {
    for (let i = 0; i < this.numRows; i++) {
      this.grid[i] = [];
      for (let j = 0; j < this.numCols; j++) {
        this.grid[i][j] = {
          type: ButtonType.Empty
        }
      }
    }
    for (let i = 0; i < this.squares.length; i++) {
      switch (this.squares[i].type) {
        case 'O':
          this.grid[this.squares[i].row][this.squares[i].column].type = ButtonType.AvailableChair;
          break;
        case 'T':
          this.grid[this.squares[i].row][this.squares[i].column].type = ButtonType.Table;
          break;
        case 'C':
          this.grid[this.squares[i].row][this.squares[i].column] = {
            type: ButtonType.ReservedChair,
            reservation:this.squares[i].reservation
          };
          break;
        case 'P':
          this.grid[this.squares[i].row][this.squares[i].column] = {
            type: ButtonType.PendingChair,
            reservation:this.squares[i].reservation
          };
          break;
      }
    }
  }

  getButtonClass(button: ButtonType): string {
    switch (button) {
      case ButtonType.Empty:
        return 'empty';
      case ButtonType.Table:
        return 'table';
      case ButtonType.AvailableChair:
        return 'chair-available';
      case ButtonType.PendingChair:
        return 'chair-pending';
      case ButtonType.SelectedChair:
        return 'chair-selected';
      case ButtonType.ReservedChair:
        return 'chair-reserved';
      default:
        return '';
    }
  }

  getButtonText(button: any): string {
    switch (button.type) {
      case ButtonType.PendingChair:
      case ButtonType.SelectedChair:
      case ButtonType.ReservedChair:
        return 'R';
      default:
        return '';
    }
  }

  getButtonClickable(i: number, j: number): boolean {
    return (this.grid[i][j].type === ButtonType.AvailableChair || this.grid[i][j].type === ButtonType.SelectedChair);
  }

  onButtonClick(i: number, j: number) {

  }

  ngOnInit(): void {
    this.initializeGrid();
  }

}
