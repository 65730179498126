import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-reservation-failed',
  templateUrl: './reservation-failed.component.html',
  styleUrls: ['./reservation-failed.component.css'],
  animations: [
    trigger('fadeAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-in-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('1s ease-in-out', style({ opacity: 0 })),
      ]),
    ]),
  ]
})
export class ReservationFailedComponent implements OnInit {
  @Input() isVisible: boolean = true;
  @Output() isVisibleChange = new EventEmitter<boolean>();
  errorStyle: any;

  constructor() { }

  setErrorStyle() {
    this.errorStyle.width = window.innerWidth * 150/512 + "px";
    // this.errorStyle.height = window.innerWidth * 20/512 + "px";
    this.errorStyle.padding = window.innerWidth * 8/512 + "px";
    this.errorStyle.fontSize = window.innerWidth * 4/512 + "px";
    this.errorStyle.lineHeight = window.innerWidth * 8/512 + "px";
    this.errorStyle.borderWidth = window.innerWidth * 1/512 + "px";
  }

  ngOnInit(): void {
    this.errorStyle = {};
    this.setErrorStyle();

    setTimeout(() => {
      this.isVisible = false;
      this.isVisibleChange.emit(this.isVisible);
    }, 5000);
  }

}
